<template>
  <div class=" clubinfoedit">
    <div class="user-details">
      <!--header-->
      <div class="profile-header profile-bg">
        <div class="row">
          <div v-if="isProfileloading==true" class="d-flex justify-content-center userpf-loader">
            <div  class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="col-md-2 text-right hovereffect pl-5 pr-0 mr-0">
            <img
              style="height:100%;width:100%;"
              :src="clubLogoImg"
              alt="User Avatar"
              :key="updateLoggedUserInfo"
              class="mr-3 avatar"
            />
            <div class="overlay">
              <b-button class="badge text" v-b-modal.profile_pic_modal>
                <i class="fas fa-upload"></i> Upload Image
              </b-button>
            </div>
          </div>
          <div class="col-md-6">
            <div class="profiler-detail pl-4 pr-5">
              <h3>{{ clubInfo.club_name }}</h3>

              <div class="d-flex">
                <div class="left mr-5" v-if="subdomain == 'waterski'">
                  <h5>{{ clubType[clubInfo.club_type] }}</h5>
                </div>
                <div class="left mr-5" v-else>
                  <h5>
                    {{ clubInfo.club_type }}
                  </h5>
                </div>
                <div
                  class="right"
                  style="margin-left: auto; align-items: center;"
                >
                  <div class="autorenewal" v-if="getSiteProps('general.club_auto_renewal_option')">
                    <span class="text-white">
                      <b-form-checkbox
                        v-model="autoRenew"
                        name="autorenewal"
                        switch
                        value="1"
                        size="lg"
                        :checked="autoRenew"
                        @change="changeAutoRenewal($event)"
                        v-if="subdomain !== 'shooting'"
                      >
                        Auto Renewal</b-form-checkbox
                      ></span
                    >
                  </div>
                </div>
              </div>

              <div class="b-der"></div>
              <div class="d-flex">
                <div class="reg_date mt-3 mr-3">
                  <div class="d-flex align-items-center">
                    <div class="up_icon mr-4">
                      <img src="/dist/img/profile/ico-registerd-date.png" />
                    </div>
                    <div class="up_text">
                      <span class="lable">Registered Date</span>
                      <span> {{ regDate || "N/A" }}</span>
                    </div>
                  </div>
                </div>
                <div class="ex_date mt-3 ml-3">
                  <div class="d-flex align-items-center">
                    <div class="up_icon mr-4">
                      <img src="/dist/img/profile/ico-expired-date.png" />
                    </div>
                    <div class="up_text">
                      <span  class="lable">Expiration Date</span>
                      <span> {{ expiryDate || "N/A" }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 pl-0 pr-5">
            <div class="mcard">
              <club-card
                :club-id="clubInfo.club_uid"
                :key="updateLoggedUserInfo"
              ></club-card>
            </div>
            <div class="text-center mt-3">
                <a href="javascript:void(0)" @click="refreshCard" class="refreshCardLink">
                  <i class="fas fa-redo" :class="refreshProgress ? 'fa-spin' : ''"></i> Refresh Card
                </a>
            </div>
          </div>
        </div>
      </div>
      <!--end header-->

      <!-- profile body start-->
      <div class="profile-body mt-4">
        <custom-alert id="alert_box" v-if="displayAlert == true" :message="alertMessage" v-on:showedAlert="resetAlert" ></custom-alert>
        <div v-if="isProfileloading==true"  class="d-flex justify-content-center userpf-loader">
          <div  class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <custom-alert v-if="displayMissingMemFields==true" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <div class="header-title">
                  <h3 v-if="prop_fields && prop_fields.club_info">{{ prop_fields.club_info.tab_title || 'Club Information'}}</h3>
                </div>
                <div class="modal-btn">
                  <b-button v-b-modal.modal-center1>
                    <span class="fa fa-edit"></span> Edit
                  </b-button>
                </div>
              </div>
              <div class="card-body">
                <div class="row" v-if="prop_fields && prop_fields.club_info ">
                  <div class="col-md-6 col-lg-4" v-if="prop_fields.club_info.clubname">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields.club_info.clubname.label || 'Club Name'}}</label>
                      <span class="title">{{
                        clubInfo.club_name || "N/A"
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label" v-if="prop_fields.club_info.clubMembership != undefined">{{ prop_fields.club_info.clubMembership.label ||'Team Membership '}}#</label>
                      <label class="form-label" v-else>Club Membership #</label>
                      <span class="title">{{
                        clubInfo.club_uid || "N/A"
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Current Status</label>
                      <span class="text-success" v-if="clubInfo.registration && clubInfo.registration.status == 4">
                        <img class="mr-1" src="/dist/img/profile/ico-status-current.png" />
                        {{ paymentStatus[clubInfo.registration.status] || "N/A" }}
                      </span>
                      <span class="text-warning" v-else-if="clubInfo.registration">
                        <img class="mr-1" src="/dist/img/profile/ico-status-pending.png" />
                        {{ paymentStatus[clubInfo.registration.status] || "N/A" }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Registered Date</label>
                      <span class="title">{{regDate || "N/A"}}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Expiration Date</label>
                      <span class="title">{{ expiryDate || "N/A"}}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="prop_fields.club_info.include_directory">
                    <div class="form-group">
                      <label class="form-label">{{ prop_fields.club_info.include_directory.label || 'Include in online club directory'}}</label>
                      <span class="title">{{ clubInfo.exclude ? "Yes" : "No" }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="prop_fields.club_info.primary_club_type">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields.club_info.primary_club_type.label || 'Primary Club Type'}}</label>
                      <span class="title">{{
                        clubInfo.primary_type_name || "N/A"
                      }}</span>

                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="prop_fields.club_info.secondary_club_type">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields.club_info.secondary_club_type.label || 'Secondary Club Type'}}</label>
                      <span class="title">{{ clubInfo.secondary_type_name || "NP - No Preference" }}</span>
                    </div>
                  </div>

                  <div class="col-md-6 col-lg-4" v-if="prop_fields.club_info.club_website">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields.club_info.club_website.label || 'Club Website'}}</label>
                      <span class="title">{{ clubInfo.website || "N/A" }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="prop_fields.club_info.email">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields.club_info.email.label || 'Email'}}</label>
                      <span class="title">{{ clubInfo.email }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="prop_fields.club_info.club_address">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields.club_info.club_address.label || 'Mailing Address'}}</label>
                      <span class="title">{{ clubInfo.address || "N/A"}}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="prop_fields.club_info.club_city">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields.club_info.club_city.label || 'City'}}</label>
                      <span class="title">{{ clubInfo.city || "N/A"}}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="prop_fields.club_info.club_state">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields.club_info.club_state.label || 'State'}}</label>
                      <span class="title">{{ clubInfo.state_code || "N/A"}}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="prop_fields.club_info.club_zip">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields.club_info.club_zip.label || 'Zip'}}</label>
                      <span class="title">{{ clubInfo.zip || "N/A"}}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="subdomain != 'shooting' && clubAdditionalInfoView">
                    <div class="form-group">
                      <label class="form-label">Region</label>
                      <span class="title">{{ clubInfo.region || "N/A"}}</span>
                    </div>
                  </div>

                  <!-- Alliance fastpitch -->
                  <div class="col-md-6 col-lg-4" v-if="!clubAdditionalInfoView">
                    <div class="form-group">
                      <label class="form-label">{{ prop_fields.club_info.member_league.label }}</label>
                      <span class="title">{{ clubInfo.memberLeageue.name || "N/A"}}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="!clubAdditionalInfoView">
                    <div class="form-group">
                      <label class="form-label">{{ prop_fields.club_info.age_division.label }}</label>
                      <span class="title" v-if="clubInfo.ageDivision != null">{{ clubInfo.ageDivision.name || "N/A"}}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="!clubAdditionalInfoView">
                    <div class="form-group">
                      <label class="form-label">{{ prop_fields.club_info.agl_team.text }}</label>
                      <span class="title">{{ clubInfo.agl_team_id || "N/A"}}</span>
                    </div>
                  </div>
                  <!-- End -->

                  <!-- Shooting info -->
                  <div class="col-md-6 col-lg-4" v-if="prop_fields.club_info.club_visible_type">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields.club_info.club_visible_type.label || 'Type'}}</label>
                      <span class="title">{{ clubInfo.visibility_type || "N/A"}}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="prop_fields.club_info.club_type">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields.club_info.club_type.label || 'Club Type'}}</label>
                      <span class="title">{{ clubInfo.club_type || "N/A"}}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="prop_fields.club_info.club_discipilines">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields.club_info.club_discipilines.label || 'Club Disciplines'}}</label>
                      <ul v-if="clubInfo.club_disciplines" class="pl-3">
                        <li v-for="(item, k) in clubInfo.club_disciplines" :key="'club_dis_'+k">
                          {{ item.name }}
                        </li>
                      </ul>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="prop_fields.club_info.club_admin_member_id">
                    <div class="form-group">
                      <label class="form-label">{{'Club Admin Member ID'}}</label>
                      <span class="title">{{ clubInfo.club_admin_member_id || "N/A"}}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="prop_fields.club_info.club_admin_name">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields.club_info.club_admin_name.label || 'Club Admin Name'}}</label>
                      <span class="title">{{ clubInfo.club_admin_name || "N/A"}}</span>
                    </div>
                  </div>
                  <!-- Shooting info -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="prop_fields_contact">
            <div class="card" v-if="(prop_fields_contact.search_by_member) || (prop_fields_contact && prop_fields_contact.search_by_name) || (prop_fields.contact && prop_fields.contact.search_by_name)">
              <div class="card-header">
                <div class="header-title">
                  <h3>{{prop_fields_contact.title || 'Primary Contact Information'}}</h3>
                </div>
                <div class="modal-btn">
                  <b-button
                    v-b-modal.member-lookup-modal
                    style="width: 100px"
                    @click="setContactType('0')"
                    v-if="subdomain != 'shooting'"
                  >
                    <span class="fa fa-edit"></span> Edit
                  </b-button>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">First Name</label>
                      <span class="title" v-if="clubInfo.primary_contact != null">{{
                        clubInfo.primary_contact.first_name || "N/A"
                      }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Last Name</label>
                      <span class="title" v-if="clubInfo.primary_contact != null">{{
                        clubInfo.primary_contact.last_name || "N/A"
                      }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4 ">
                    <div class="form-group">
                      <label class="form-label">Member #</label>
                      <span class="title" v-if="clubInfo.primary_contact != null">{{
                        clubInfo.primary_contact["membership_id"] || "N/A"
                      }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">City</label>
                      <span class="title" v-if="clubInfo.primary_contact != null">{{
                        clubInfo.primary_contact.city || "N/A"
                      }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">State</label>
                      <span class="title" v-if="clubInfo.primary_contact != null">{{
                        clubInfo.primary_contact.state_code || "N/A"
                      }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4 ">
                    <div class="form-group">
                      <label class="form-label">Zip Code</label>
                      <span class="title" v-if="clubInfo.primary_contact != null">{{
                        clubInfo.primary_contact["zip"] || "N/A"
                      }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4 ">
                    <div class="form-group">
                      <label class="form-label">Phone</label>
                      <span class="title" v-if="clubInfo.primary_contact != null">{{
                        clubInfo.primary_contact.phone_1 || "N/A"
                      }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Email</label>
                      <span class="title" v-if="clubInfo.primary_contact != null">{{
                        clubInfo.primary_contact.email || "N/A"
                      }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <div  class="col-md-6" v-if="clubInfo.primary_contact != null">
                    <div class="form-group">
                      <label class="form-label">Membership Status <template><div class="d-inline ml-2" id="tooltip" v-if="subdomain != 'shooting'"><i class="fas fa-info-circle"></i></div><b-tooltip target="tooltip" class="white">{{clubInfo.primary_contact.member.membership_status_info}} <br> Click here to review all Membership Statuses - <a href="https://www.teamusa.org/usa-water-ski/membership-status-types" target="_blank">https://www.teamusa.org/usa-water-ski/membership-status-types</a></b-tooltip></template></label>
                      <span class="text-success" v-if="clubInfo.primary_contact.member.status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.primary_contact.member.status_text }} (Expires {{ clubInfo.primary_contact.member.valid_thru | formatDob }})</span>
                      <span class="text-warning"  v-else-if="clubInfo.primary_contact.member.status >=7"> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{ clubInfo.primary_contact.member.status_text }}</span>
                      <span class="text-danger"  v-else> <img style="width:18px;" class="mr-1" src="/dist/img/profile/deactivated.png"> {{ clubInfo.primary_contact.member.status_text }}</span>
                    </div>
                  </div>
                  <template v-if="clubInfo.primary_contact != null">
                    <div class="col-md-6" v-if="clubInfo.primary_contact.member.member_type_id != 2">
                      <div class="form-group">
                        <label class="form-label">Waiver Status</label>
                        <span class="text-success" v-if="clubInfo.primary_contact.member.waiver_status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> Signed</span>
                        <span class="text-warning"  v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                      </div>
                    </div>
                  </template>

                  <template v-if="subdomain == 'waterski' && clubInfo.primary_contact != null">
                    <div  class="col-md-6">
                      <div class="form-group" v-if="clubInfo.primary_contact.member.safe_sport_screening_compliance == true">
                        <label class="form-label" >Safe Sport Training Status</label>
                        <span class="text-success" v-if="clubInfo.primary_contact.member.safe_sport != null && clubInfo.primary_contact.member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.primary_contact.member.safe_sport.safe_sport_status_text}} (Expires {{ clubInfo.primary_contact.member.safe_sport.expiration }})</span>
                        <span class="text-warning" v-else-if="clubInfo.primary_contact.member.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubInfo.primary_contact.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="clubInfo.primary_contact.member.safe_sport.safe_sport_status == 13 && clubInfo.primary_contact.member.safe_sport.expiration"><br>(On {{ clubInfo.primary_contact.member.safe_sport.expiration }})</template></span>
                        <span class="text-warning" v-else>Not Started</span>
                      </div>
                      <div class="form-group" v-else>
                        <label class="form-label">Safe Sport Training Status</label>
                        <span class="">N/A</span>
                      </div>
                    </div>
                    <div  class="col-md-6">
                      <div class="form-group" v-if="clubInfo.primary_contact.member.safe_sport_screening_compliance == true">
                        <label class="form-label">Background Screening Status <span v-if="clubInfo.primary_contact.member.background_screening != null && clubInfo.primary_contact.member.background_screening.background_screening_info" v-b-tooltip.hover :title="clubInfo.primary_contact.member.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span>
                          <template v-if="getSiteProps('tooltips.backgroundScreening')">
                              <div class="d-inline" id="bgscreen-tooltip" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }">
                                  <img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" />
                              </div>
                              <b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip" class="white">
                                  <p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p>
                              </b-tooltip>
                          </template>
                        </label>
                        <span class="text-success" v-if="clubInfo.primary_contact.member.background_screening != null && clubInfo.primary_contact.member.background_screening.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.primary_contact.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ clubInfo.primary_contact.member.background_screening.expiration | formatDob }})</span>
                        <span class="text-warning" v-else-if="clubInfo.primary_contact.member.background_screening != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubInfo.primary_contact.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="clubInfo.primary_contact.member.background_screening.status == 6 && clubInfo.primary_contact.member.background_screening.expiration"><br>(On {{ clubInfo.primary_contact.member.background_screening.expiration | formatDob }})</template></span>
                        <span class="text-warning" v-else>Not Started</span>
                      </div>
                      <div class="form-group" v-else>
                        <label class="form-label">Background Screening Status</label>
                        <span class="">N/A</span>
                      </div>
                    </div>
                    <div  class="col-md-6">
                      <div class="form-group" v-if="clubInfo.primary_contact.member.safe_sport_screening_compliance == true">
                        <label class="form-label">MVR Status <span v-if="clubInfo.primary_contact.member.background_screening != null && clubInfo.primary_contact.member.background_screening.background_screening_info" v-b-tooltip.hover :title="clubInfo.primary_contact.member.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span>
                        </label>
                        <span class="text-success" v-if="clubInfo.primary_contact.member.background_mvr_review != null && clubInfo.primary_contact.member.background_mvr_review.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.primary_contact.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ clubInfo.primary_contact.member.background_mvr_review.expiration | formatDob }})</span>
                        <span class="text-warning" v-else-if="clubInfo.primary_contact.member.background_mvr_review != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubInfo.primary_contact.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="clubInfo.primary_contact.member.background_mvr_review.status == 6 && clubInfo.primary_contact.member.background_mvr_review.expiration"><br>(On {{ clubInfo.primary_contact.member.background_mvr_review.expiration | formatDob }})</template></span>
                        <span class="text-warning" v-else>Not Started</span>
                      </div>
                      <div class="form-group" v-else>
                        <label class="form-label">MVR Status</label>
                        <span class="">N/A</span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="card" v-else>
              <div class="card-header">
                <div class="header-title">
                  <h3>{{prop_fields_contact.tab_title || 'Contact Information'}}</h3>
                </div>
                <div class="modal-btn">
                  <b-button
                    v-b-modal.edit-club-contact
                  >
                    <span class="fa fa-edit"></span> Edit
                  </b-button>
                </div>
              </div>
              <div class="card-body" v-if="prop_fields_contact">
                <div class="row" v-if="prop_fields_contact">
                  <div class="col-md-12 col-lg-12" v-if="clubAdditionalInfoView && prop_fields_contact && prop_fields_contact.email">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields_contact.email.label || 'E-Mail'}}</label>
                      <span class="title" v-if="clubInfo.primary_contact != null">{{
                        clubInfo.primary_contact.email || "N/A"
                      }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                    <hr>
                  </div>
                  <div class="col-md-12 col-lg-12" v-if="clubAdditionalInfoView && prop_fields_contact && prop_fields_contact.club_address" >
                    <div class="form-group">
                      <label class="form-label">{{prop_fields_contact.club_address.label || 'Mailing Address'}}</label>
                      <span class="title">{{
                        clubInfo.primary_contact.address1 || "N/A"
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="clubAdditionalInfoView && prop_fields_contact && prop_fields_contact.club_city">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields_contact.club_city.label || 'City'}}</label>
                      <span class="title" v-if="clubInfo.primary_contact != null">{{
                        clubInfo.primary_contact.city || "N/A"
                      }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="clubAdditionalInfoView && prop_fields_contact && prop_fields_contact.club_state">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields_contact.club_state.label || 'State'}}</label>
                      <span class="title" v-if="clubInfo.primary_contact != null" >{{
                        clubInfo.primary_contact.state_code || clubInfo.state_code || "N/A"
                      }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4 " v-if="clubAdditionalInfoView && prop_fields_contact && prop_fields_contact.club_zip">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields_contact.club_zip.label || 'Zip Code'}}</label>
                      <span class="title" v-if="clubInfo.primary_contact != null">{{
                        clubInfo.primary_contact["zip"] || "N/A"
                      }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <!-- Alliancefastpitch -->
                  <div class="col-md-12 col-lg-12" v-if="!clubAdditionalInfoView && prop_fields_contact && prop_fields_contact.email">
                    <div class="row">
                      <div class="form-group col-md-6 col-md-4">
                        <label class="form-label">Head Coach Name</label>
                        <span class="title">{{
                            clubInfo.team_head_coach || "N/A"
                          }}</span>
                      </div>
                      <div class="form-group col-md-6 col-md-4">
                        <label class="form-label">{{prop_fields_contact.email.label || 'E-Mail'}}</label>
                        <span class="title">{{
                            clubInfo.email || "N/A"
                          }}</span>
                      </div>
                    </div>
                    <hr>
                  </div>
                  <div class="col-md-12 col-lg-12" v-if="!clubAdditionalInfoView && prop_fields_contact && prop_fields_contact.address1" >
                    <div class="form-group">
                      <label class="form-label">{{prop_fields_contact.address1.label || 'Mailing Address'}}</label>
                      <span class="title" v-if="!clubAdditionalInfoView && clubInfo.address != null">{{
                          clubInfo.address || "N/A"
                        }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="!clubAdditionalInfoView && prop_fields_contact && prop_fields_contact.city">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields_contact.city.label || 'City'}}</label>
                      <span class="title" v-if="clubInfo.primary_contact">{{
                          clubInfo.city || "N/A"
                        }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="!clubAdditionalInfoView && prop_fields_contact && prop_fields_contact.country">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields_contact.country.label || 'Country'}}</label>
                      <span class="title" v-if="clubInfo.primary_contact">{{
                          clubInfo.country || "N/A"
                        }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="!clubAdditionalInfoView && prop_fields_contact && prop_fields_contact.state_code">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields_contact.state_code.label || 'State'}}</label>
                      <span class="title" v-if="clubInfo.primary_contact != null">{{
                         clubInfo.state_code || "N/A"
                        }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4 " v-if="!clubAdditionalInfoView && prop_fields_contact && prop_fields_contact.zip">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields_contact.zip.label || 'Zip Code'}}</label>
                      <span class="title" v-if="clubInfo.primary_contact != null">{{
                          clubInfo.zip || "N/A"
                        }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <!-- Alliancefastpitch End -->
                  <template v-if="clubInfo.primary_contact && clubInfo.primary_contact.physical_address">
                    <div class="col-md-12 col-lg-12" v-if="prop_fields_contact.club_physical_address">
                      <hr>
                      <div class="form-group">
                        <label class="form-label">{{prop_fields_contact.club_physical_address.label || 'Physical Address'}}</label>
                        <span class="title">{{
                          clubInfo.primary_contact.physical_address || "N/A"
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4" v-if="prop_fields_contact.club_physical_city">
                      <div class="form-group">
                        <label class="form-label">{{prop_fields_contact.club_physical_city.label || 'City'}}</label>
                        <span class="title">{{
                          clubInfo.primary_contact.physical_city || "N/A"
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4" v-if="prop_fields_contact.club_physical_state">
                      <div class="form-group">
                        <label class="form-label">{{prop_fields_contact.club_physical_state.label || 'State'}}</label>
                        <span class="title">{{
                          clubInfo.primary_contact.physical_state_code || clubInfo.primary_contact.physical_state || "N/A"
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4 " v-if="prop_fields_contact.club_physical_zip">
                      <div class="form-group">
                        <label class="form-label">{{prop_fields_contact.club_physical_zip.label || 'Zip Code'}}</label>
                        <span class="title">{{
                          clubInfo.primary_contact.physical_zip || "N/A"
                        }}</span>
                      </div>
                    </div>
                  </template>
                  <div class="col-md-12">
                    <hr>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="prop_fields_contact && prop_fields_contact.homephone && !isNotalliancefastpitch">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields_contact.homephone.label || 'Phone'}}</label>
                      <span class="title" v-if="clubInfo.primary_contact != null && !isNotalliancefastpitch">{{
                          clubInfo.phone || "N/A"
                        }}</span>
                      <span class="title" v-else >N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="prop_fields_contact && prop_fields_contact.mobilephone">
                    <div class="form-group">
                      <label class="form-label">{{prop_fields_contact.mobilephone.label || 'Phone2'}}</label>
                      <span class="title" v-if="clubInfo.primary_contact != null && isNotalliancefastpitch">{{
                        clubInfo.primary_contact.phone_1 || "N/A"
                      }}</span>
                      <span class="title" v-if="clubInfo.primary_contact != null && !isNotalliancefastpitch">{{
                          clubInfo.phone_2 || "N/A"
                        }}</span>
                      <span class="title" v-else-if="!isNotalliancefastpitch" >N/A</span>
                    </div>
                  </div>
                  <template v-if="clubInfo.primary_contact != null && isNotalliancefastpitch">
                    <div  class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" >Safe Sport Training Status</label>
                        <span class="text-success" v-if="clubInfo.primary_contact.member.safe_sport != null && clubInfo.primary_contact.member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.primary_contact.member.safe_sport.safe_sport_status_text}} (Expires {{ clubInfo.primary_contact.member.safe_sport.expiration }})</span>
                        <span class="text-warning" v-else-if="clubInfo.primary_contact.member.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubInfo.primary_contact.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="clubInfo.primary_contact.member.safe_sport.safe_sport_status == 13 && clubInfo.primary_contact.member.safe_sport.expiration"><br>(On {{ clubInfo.primary_contact.member.safe_sport.expiration }})</template></span>
                        <span class="text-warning" v-else>Not Started</span>
                      </div>
                    </div>
                    <div  class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">Background Screen Status <span v-if="clubInfo.primary_contact.member.background_screening != null && clubInfo.primary_contact.member.background_screening.background_screening_info" v-b-tooltip.hover :title="clubInfo.primary_contact.member.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                        <span class="text-success" v-if="clubInfo.primary_contact.member.background_screening != null && clubInfo.primary_contact.member.background_screening.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.primary_contact.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ clubInfo.primary_contact.member.background_screening.expiration | formatDob }})</span>
                        <span class="text-warning" v-else-if="clubInfo.primary_contact.member.background_screening != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubInfo.primary_contact.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="clubInfo.primary_contact.member.background_screening.status == 6 && clubInfo.primary_contact.member.background_screening.expiration"><br>(On {{ clubInfo.primary_contact.member.background_screening.expiration | formatDob }})</template></span>
                        <span class="text-warning" v-else>Not Started</span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row profile-body" v-if="subdomain == 'waterski'">
          <div class="col-md-12 mb-2">
            <div class="card">
              <div class="card-header">
                <div class="header-title">
                  <h3>Site Information</h3>
                </div>
                <div class="modal-btn">
                  <b-button
                    @click="
                      $bvModal.show('ski_sites_modal');
                      setVal('Add Ski-Sites', 0);
                    "
                    style="width: 100px"
                  >
                    <span class="fa fa-edit"></span> Add
                  </b-button>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <b-table
                  id="ski-site-table"
                  :items="clubInfo.team_sites"
                  :fields="tblSiteFields"
                  hover
                  show-empty
                >
                  <template :fields="items"></template>
                  <template #empty="scope">
                    <h6>{{ scope.emptyText }}</h6>
                  </template>
                  <template #cell(actions)="row">
                    <div class="text-left py-0 align-middle">
                      <div class="btn-group btn-group-sm">
                        <button
                          class="btn btn-primary ml-1"
                          @click="
                            $bvModal.show('ski_sites_modal');
                            setVal('Update Ski-Sites', row.item.id);
                          "
                        >
                          <i class="fas fa-pencil-alt"></i>
                        </button>
                        <button
                          class="btn btn-danger ml-1"
                          @click="delTeamSites(row.item.id)"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
        <div class="row profile-body mb-3"  v-if="subdomain == 'alliancefastpitch'">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div class="header-title">
                            <h3>Team Roster</h3>
                        </div>
                    </div>
                    <div class="card-body pt-0 table-responsive">
                        <b-table
                            id="my-table"
                            :items="clubInfo.roster"
                            :fields="tblrosterFields"
                            class="mt-3"
                            hover
                            show-empty
                        >
                            <template
                                :fields="items"
                            >
                                <div class="text-left py-0 align-middle">
                                    <div class="btn-group btn-group-sm">
                                        <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                                        <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                                        </button>
                                        <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                                    </div>
                                </div>
                            </template>
                            <template #empty="scope">
                                <h4>{{ scope.emptyText }}</h4>
                            </template>
                              
                            <template #cell(first_name)="row">
                                {{row.item.personal_details.first_name}}
                            </template>
                            <template #cell(last_name)="row">
                                {{row.item.personal_details.last_name}}
                            </template>
                            <template #cell(membership_name)="row">
                                {{row.item.latest_registration.membership.name}}
                            </template>
                            <template #cell(membership_endtate)="row">
                              {{formatDate(row.item.latest_registration.valid_thru)}}
                            </template>
                            <template #cell(member_status)="row">
                                {{row.item.status_text}}
                            </template>
                        </b-table>


                    </div>
                </div>
            </div>
        </div>
        <div class="row profile-body mb-3"  v-if="subdomain == 'alliancefastpitch'">
          <div class="col-md-12">
              <div class="card">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-md-7">
                          <h3>Invited Players and Staff</h3>
                      </div>                                
                      <div class="col-md-4 text-right pt-1">
                        <input type="file" name="upload_csv" id="upload_csv" accept=".xls,.xlsx" @change="fileChoosed" ref="file">
                        <button class="btn btn-primary darkblubtn btn-sm" @click="uploadCSV" v-if="file" :disabled="btnloading">
                            <i class="fas fa-upload"></i> Upload
                        </button>
                      </div>
                      <div class="col-md-1 text-right pt-1">
                        <button class="btn btn-primary btn-blue btn-sm" v-b-modal.invitePlayer-modal><i class="fa fa-plus"></i> Add</button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body pt-0 table-responsive">
                      <b-table
                          id="my-table"
                          :items="clubInfo.invitedPlayers"
                          :fields="tblinvitePlayerFields"
                          class="mt-3"
                          hover
                          show-empty
                      >
                          <template
                              :fields="items"
                          >
                          </template>
                          <template #empty="scope">
                              <h4>{{ scope.emptyText }}</h4>
                          </template>
                            
                          <template #cell(flag)="row">
                              {{(row.item.flag ==1 ) ? 'Registered' : 'Pending'}}
                          </template>   
                          <template #cell(actions)="row">                           
                            <div class="text-left py-0 align-middle" >
                                <div class="btn-group btn-group-sm" v-if="row.item.flag == 1 ">
                                  <button class="btn btn-danger ml-1"><i
                                       class="fas fa-check" aria-hidden="true"></i></button>                                  
                                </div>
                                <div class="btn-group btn-group-sm" v-else>                                             
                                    <button class="btn btn-danger ml-1" @click="delPlayers(row.item.id)"><i
                                        class="fas fa-trash"></i></button>
                                </div>
                            </div>
                        </template>                               
                      </b-table>
                  </div>
              </div>
          </div>
        </div>

        <div class="row profile-body" v-if="subdomain == 'waterski'">
          <div class="col-md-12">
            <div class="card mt-3 border-none">
              <div class="card-header bg-white">
                    <div class="header-title">
                      <h3>Certificate(s) of Insurance
                        <b-form-checkbox
                          v-model="viewInsurancePrevious"
                          switch
                          size="lg"
                          class="d-inline-block ml-3"
                          :checked="viewInsurancePrevious"
                          @change="changeInsuranceViewPrevious($event)"
                      ><small>View Previous Years</small></b-form-checkbox>
                      </h3>
                    </div>
                    <div class="modal-btn">
                      <router-link v-if="!membershipExpired" :to="insuranceUrl" style="width: 62px; height: 26px; padding: 2px 19px; background: none; color: rgb(0, 0, 0); font-size: 14px; line-height: 10px; border-color: #6c757d!important; border: 1px solid; border-radius: 5px;">
                        <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Add
                      </router-link>
                    </div>
                <b-modal ref="modal"
                         ok-only
                         ok-title="Add Insurance"
                         @show="resetModal"
                         @hidden="resetModal"
                         @ok="handleInsuranceOk" id="modal-center6" size="lg" centered title="Certificate of Insurance Request">
                  <form ref="form" @submit.stop.prevent="handleInsuranceSubmit">
                    <div class="popupbody">
                      <!--<h5>Certificate of Insurance Request</h5>-->
                      <p class="font-13 mb-1">Complete for each third party requiring a certificate of insurance from your club with respect to USA Water Ski & Wake Sports sanctioned events. Each request is $50.</p>
                      <p class="font-13 text-danger"><i>Note: Coverage only applies with respect to tournaments, practices, exhibitions, clinics, and related activities sanctioned and approved by USA Water Ski & Wake Sports.</i> </p>
                      <div class="newname-inner">
                        <div class="row">
                          <div class="col-md-6" v-if="notACompany">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label for="companyName" class="form-label">Company Name <sup class="text-danger">*</sup></label>
                                  <input id="companyName" type="text" maxlength="250" class="form-control" name="insuranceForm.name" value="" v-model="insuranceForm.company_name">
                                  <span class="text-sm text-danger">{{ insuranceErrors.company_name }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3" v-if="notACompany">
                            <div class="form-group">
                              <label for="firstName" class="form-label">First Name <sup class="text-danger">*</sup></label>
                              <input id="firstName" type="text" class="form-control" maxlength="90" name="insuranceForm.first_name" value=""  v-model="insuranceForm.first_name">
                              <span class="text-sm text-danger">{{ insuranceErrors.first_name }}</span>
                            </div>

                          </div>
                          <div class="col-md-3" v-if="notACompany">
                            <div class="form-group">
                              <label for="lastName" class="form-label">Last Name<sup class="text-danger">*</sup></label>
                              <input id="lastName" type="text" class="form-control" maxlength="90" name="insuranceForm.last_name" value=""  v-model="insuranceForm.last_name">
                              <span class="text-sm text-danger">{{ insuranceErrors.last_name }}</span>
                            </div>
                          </div>
                          <div class="col-md-3 mt-4">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" id="notACompany"
                                     name="notACompany" v-model="notACompany" value="1">
                              <label class="d-flex mr-1" for="notACompany">Not a company</label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="address1" class="form-label">Address 1<sup class="text-danger">*</sup> </label>
                              <input id="address1" type="text" class="form-control" maxlength="45" name="insuranceForm.address1" value="" v-model="insuranceForm.address1">
                              <span class="text-sm text-danger">{{ insuranceErrors.address1 }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="address2" class="form-label">Address 2 </label>
                              <input id="address2" type="text" class="form-control" maxlength="45" name="insuranceForm.address2" value="" v-model="insuranceForm.address2">
                              <span class="text-sm text-danger">{{ insuranceErrors.address2 }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="city" class="form-label">City<sup class="text-danger">*</sup></label>
                              <input id="city" type="text" class="form-control" maxlength="45" name="insuranceForm.city" value="" v-model="insuranceForm.city">
                              <span class="text-sm text-danger">{{ insuranceErrors.city }}</span>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="state" class="form-label">State <sup class="text-danger">*</sup> </label>
                              <select class="form-control" name="insuranceForm.state_code" v-model="insuranceForm.state_code">
                                <option  v-for="listOfState in listOfStates" :key="listOfState.state" :value="listOfState.state_code">{{ listOfState.state }}</option>
                              </select>
                              <span class="text-sm text-danger">{{ insuranceErrors.state_code }}</span>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="zipCode" class="form-label">Zip Code <sup class="text-danger">*</sup> </label>
                              <input id="zipCode" type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" name="insuranceForm.zip" v-model="insuranceForm.zip">
                              <span class="text-sm text-danger">{{ insuranceErrors.zip }}</span>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="homePhone" class="form-label">Phone  <sup class="text-danger">*</sup> </label>
                              <vue-tel-input :inputOptions="options" v-model="insuranceForm.home_phone" ref="phone" maxlength="20" v-bind="homeProps" :validCharactersOnly="true"></vue-tel-input>
                              <span class="text-sm text-danger">{{ insuranceErrors.home_phone }}</span>
                            </div>
                          </div>
                          <!-- <div class="col-md-4">
                           <div class="form-group">
                           <label for="workPhone" class="form-label">Work Phone</label>
                               <vue-tel-input :inputOptions="options" v-model="insuranceForm.work_phone" ref="phone" maxlength="20" v-bind="workProps" :validCharactersOnly="true"></vue-tel-input>
                               <span class="text-sm text-danger">{{ insuranceErrors.work_phone_country_code }}</span>
                           </div>
                           </div>-->

                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="certificateFor" class="form-label">This certificate is for:<sup class="text-danger">*</sup> </label>
                              <div class="form-group ">
                                <label class="form-label" for="yearRoundclubactivity"> <input type="radio" id="yearRoundclubactivity"  name="insuranceForm.certificate_type" v-model="insuranceForm.certificate_type" value="year"> Year - Round Club Activity</label>
                                <label class="form-label" for="specificEvent">

                                  <input type="radio" id="specificEvent" maxlength="20"  name="insuranceForm.certificate_type" v-model="insuranceForm.certificate_type" value="specificEvent"> Specific Event</label><br>
                                <span class="text-sm text-danger">{{ insuranceErrors.certificate_type }}</span>
                              </div>
                            </div>

                          </div>
                          <div class="col-md-12">
                            <div class="form-group certificatefor" v-if="insuranceForm.certificate_type == 'specificEvent'">
                              <span class="font-13 text-danger"><i>Note: Certificate for Specific sanctioned events will not be issued until the event is sanctioned and approved by USA Water Ski & Wake Sports.</i></span><br>
                              <label for="eventType" class="form-label">Specific Event Title<sup class="text-danger">*</sup> </label>
                              <input type="text"  class="form-control" maxlength="45" name="insuranceForm.specific_event_title" v-model="insuranceForm.specific_event_title">
                              <span class="text-sm text-danger">{{ insuranceErrors.specific_event_title }}</span>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group specificeventtype" v-if="insuranceForm.certificate_type == 'specificEvent'">
                              <label for="eventType" class="form-label">Specific Sanctioned Event Type <sup class="text-danger">*</sup></label>
                              <div class="form-group row">
                                <label class="form-label" for="tournament"><input type="radio" id="tournament" name="insuranceForm.sanctioned_type" v-model="insuranceForm.sanctioned_type" value="tournament"> Tournament</label>
                                <label class="form-label" for="practice"><input type="radio" id="practice" name="insuranceForm.sanctioned_type" v-model="insuranceForm.sanctioned_type" value="practice"> Practice</label><br>
                                <label class="form-label" for="basicskillclinic"><input type="radio" id="basicskillclinic" name="insuranceForm.sanctioned_type" v-model="insuranceForm.sanctioned_type" value="basicskillclinic"> Basic Skills Clinic</label>
                                <label class="form-label" for="exhibition"><input type="radio" id="exhibition" name="insuranceForm.sanctioned_type" v-model="insuranceForm.sanctioned_type" value="exhibition"> Exhibition</label><br>
                                <div v-show="insuranceForm.sanctioned_type == 'tournament'">
                                  <p class="font-13 mb-1">Note: Certificates for specific sanctioned events will not be issued until the event is sanctioned and approved by USA-WSWS.</p>
                                </div>
                                <div v-show="insuranceForm.sanctioned_type == 'practice'">
                                  <p class="font-13 mb-1">Note: Certificates for specific sanctioned events will not be issued until the event is sanctioned and approved by USA-WSWS.</p>
                                </div>
                                <div v-show="insuranceForm.sanctioned_type == 'basicskillclinic'">
                                  <p class="font-13 mb-1">Note: Certificates for specific sanctioned events will not be issued until the event is sanctioned and approved by USA-WSWS.</p>
                                </div>
                                <div v-show="insuranceForm.sanctioned_type == 'exhibition'">
                                  <p class="font-13 mb-1">Note: Certificates for specific sanctioned events will not be issued until the event is sanctioned and approved by USA-WSWS.</p>
                                </div>
                                <span class="text-sm text-danger">{{ insuranceErrors.sanctioned_type }}</span>
                              </div>
                              <div class="row" v-if="insuranceForm.certificate_type == 'specificEvent'">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="eventType" class="form-label">Event Sanction#  <sup class="text-danger">*</sup></label>
                                    <input id="eventSanction" type="text" name="insuranceForm.event_sanction" maxlength="45" v-model="insuranceForm.event_sanction" class="form-control">
                                    <span class="text-sm text-danger">{{ insuranceErrors.event_sanction }}</span>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group insurence-datepicker">
                                      <label for="eventType" class="form-label">Specific Events Start Date </label><br>

                                      <date-pick
                                          class=""
                                          v-model="insuranceForm.start_date"
                                          :pickTime="false"
                                          name="insuranceForm.start_date"
                                          :selectableYearRange="{from: 1900, to: 2021}"
                                          :format="'MM/DD/YYYY'"
                                          v-mask="'##/##/####'"
                                          :isDateDisabled="isFutureDate"
                                          placeholder="MM/DD/YYYY"
                                          :disabled="true"
                                          :parseDate="parseDatePick"
                                      ></date-pick>

                                      <!--<input id="specificeventdate" type="text" name="insuranceForm.start_date" maxlength="45" v-model="insuranceForm.start_date" class="form-control">-->
                                      <span class="text-sm text-danger">{{ insuranceErrors.start_date }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group insurence-datepicker">
                                      <label for="eventType" class="form-label">Specific Events End Date </label><br>

                                      <date-pick
                                          class=""
                                          v-model="insuranceForm.end_date"
                                          :pickTime="false"
                                          name="insuranceForm.end_date"
                                          :selectableYearRange="{from: 1900, to: 2021}"
                                          :format="'MM/DD/YYYY'"
                                          v-mask="'##/##/####'"
                                          :isDateDisabled="isFutureDate"
                                          placeholder="MM/DD/YYYY"
                                          :disabled="true"
                                          :parseDate="parseDatePick"
                                      ></date-pick>

                                      <!--<input id="specificeventdate" type="text" name="insuranceForm.end_date" maxlength="45" v-model="insuranceForm.end_date" class="form-control">-->
                                      <span class="text-sm text-danger">{{ insuranceErrors.end_date }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group ">
                              <label class="form-label">Has the club entered into any agreement, contract, or permit which contains assumption of liability, indemnification, or hold harmless language? <sup class="text-danger">*</sup></label>
                              <div class="">
                                <label class="form-label" for="agreeyes"><input type="radio" id="agreeyes" name="insuranceForm.team_has_any_agreement" v-model="insuranceForm.team_has_any_agreement" value="Y"> Yes</label><br>

                              </div>
                              <div class="">
                                <label class="form-label" for="agreeno"> <input type="radio" id="agreeno" name="insuranceForm.team_has_any_agreement" v-model="insuranceForm.team_has_any_agreement" value="N"> No</label><br>
                              </div>
                              <span class="text-sm text-danger">{{ insuranceErrors.team_has_any_agreement }}</span>
                              <span class="font-13 text-danger"><i> (A copy of the document should be forwarded to USA-WSWS)</i></span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group ">
                              <label class="form-label">Does the certificate holder require additional insured status? <sup class="text-danger">*</sup></label>
                              <div class="">
                                <label class="form-label" for="ins-status-yes"><input type="radio" id="ins-status-yes" name="insuranceForm.additional_insurance" v-model="insuranceForm.additional_insurance" value="Y"> Yes</label><br>

                              </div>
                              <div class="">
                                <label class="form-label" for="ins-status-no"> <input type="radio" id="ins-status-no" name="insuranceForm.additional_insurance" v-model="insuranceForm.additional_insurance" value="N"> No</label><br></div>
                              <span class="text-sm text-danger">{{ insuranceErrors.additional_insurance }}</span>
                            </div>
                            <div class="form-group " v-show="insuranceForm.additional_insurance=='Y'">
                              <b-form-group label="Please check the relationship of the additional insured:">
                                <b-form-checkbox-group
                                    id="additional_insurance_member"
                                    v-model="insuranceForm.additional_insurance_member"
                                    :options="additionalInsuranceMemberOptions"
                                    name="additional_insurance_member"
                                ></b-form-checkbox-group>
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </b-modal>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <div class="table-responsive">
                  <b-table
                      id="my-table"
                      :items="insuranceList"
                      :fields="tblInsuredFields"
                      sort-by="transaction_time"
                      sort-desc="Descending"
                      class="mt-3"
                      hover
                      show-empty
                      :bind="'insuranceListKey-' + insuranceListKey"
                  >
                    <template
                        :fields="items"
                    >
                      <div class="text-left py-0 align-middle">
                        <div class="btn-group btn-group-sm">
                          <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                          <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                          </button>
                          <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                        </div>
                      </div>
                    </template>
                    <template #empty="scope">
                      <h4>{{ scope.emptyText }}</h4>
                    </template>
                    <template #cell(actions)="row">
                      <div class="text-left py-0 align-middle">
                        <div class="btn-group btn-group-sm">
                          <button class="btn btn-info" @click="viewRow(row.item, row.index, $event.target)"><i class="fas fa-eye"></i></button>
                          <!--<button class="btn btn-primary ml-1" @click="editRow(row.item, row.index, $event.target)"><i
                              class="fas fa-pencil-alt"></i></button>-->
                          <button class="btn btn-danger ml-1" @click="deleteInsurance(row.item.id)"><i
                              class="fas fa-trash"></i></button>
                        </div>
                      </div>
                    </template>
                    <!--<template #table-busy>
                        <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                        </div>
                    </template>-->
                  </b-table>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
          <div class="col-md-12" v-if="subdomain != 'alliancefastpitch'">
            <div class="card mt-3 border-none">
              <div class="card-header bg-white">
                <div class="header-title">
                  <h3>Practice/Exhibition Sanctioning
                    <b-form-checkbox class="d-inline-block ml-3"
                        v-model="viewSanctionPrevious"
                        switch
                        size="lg"
                        :checked="viewSanctionPrevious"
                        @change="changeSanctionViewPrevious($event)"
                    ><small>View Previous Years</small>
                  </b-form-checkbox>
                </h3>
                </div>
<!--                <div class="modal-btn">-->
<!--                  <b-button v-b-modal.modal-center6 style="width: 100px">-->
<!--                    <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Add-->
<!--                  </b-button>-->
<!--                </div>-->
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <div class="table-responsive">
                  <b-table
                      id="my-table"
                      :items="sanctionList"
                      :fields="tblSanctionFields"
                      class="mt-3"
                      hover
                      show-empty
                      sort-by="transaction_time"
                      sort-desc="Descending"
                      :bind="'sanctionListKey-' + sanctionListKey"
                  >
                    <template
                        :fields="items"
                    >
                    </template>
                    <template #cell(guest_registration_url)="row">
                      <a :href="row.item.guest_registration_url" target="_blank">{{ row.item.guest_registration_url}}</a>
                    </template>
                    <template #cell(sanction)>
                      <a :href="sanctioningUrl" target="_blank" title="Sanction" class="btn btn-warning btn-sm"><i class="fa fa-list"></i></a>
                    </template>
                    <template #cell(waiver)="row">
                      <div class="text-left py-0 align-middle">
                        <div class="btn-group btn-group-sm">
                          <button @click="downloadDocs(row.item.registration_id, 'sanctionWaiver')"
                                  class="download-btn ml-1 mt-0"><img src="/dist/img/profile/ico-download.png"></button>
                        </div>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
      <!-- profile body end-->
    </div>
    <!-- Info modal -->
    <b-modal
        :id="infoModal.id"
        :title="infoModal.title"
        size="lg"
        ok-only
        @hide="resetInfoModal"
    >
      <div class="popupbody">
        <p class="font-13 mb-1">Complete for each third party requiring a certificate of insurance from your club with respect to USA Water Ski & Wake Sports sanctioned events. Each request is $50.</p>
        <p class="font-13 text-danger"><i>Note: Coverage only applies with respect to tournaments, practices, exhibitions, clinics, and related activities sanctioned and approved by USA Water Ski & Wake Sports.</i> </p>
        <div class="newname-inner">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="firstName" class="form-label">First Name <sup class="text-danger">*</sup></label>
                <input id="firstName" type="text" class="form-control" readonly maxlength="45" name="infoModal.content.first_name" value=""  v-model="infoModal.content.first_name">
              </div>

            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="lastName" class="form-label">Last Name<sup class="text-danger">*</sup></label>
                <input id="lastName" type="text" class="form-control" readonly maxlength="45" name="infoModal.content.last_name" value=""  v-model="infoModal.content.last_name">
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-2 pr-0 pl-0">
                  <strong class="font13">-OR-</strong>
                </div>
                <div class="col-md-10 pl-0">
                  <div class="form-group">
                    <label for="companyName" class="form-label">Company Name <sup class="text-danger">*</sup></label>
                    <input id="companyName" type="text" maxlength="250" class="form-control" readonly name="infoModal.content.name" value="" v-model="infoModal.content.company_name">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="address1" class="form-label">Address 1<sup class="text-danger">*</sup> </label>
                <input id="address1" type="text" class="form-control" readonly maxlength="45" name="infoModal.content.address1" value="" v-model="infoModal.content.address1">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="address2" class="form-label">Address 2 </label>
                <input id="address2" type="text" class="form-control" readonly maxlength="45" name="infoModal.content.address2" value="" v-model="infoModal.content.address2">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="city" class="form-label">City<sup class="text-danger">*</sup></label>
                <input id="city" type="text" class="form-control" readonly maxlength="45" name="infoModal.content.city" value="" v-model="infoModal.content.city">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="state" class="form-label">State <sup class="text-danger">*</sup> </label>
                <select class="form-control" disabled name="infoModal.content.state_code" v-model="infoModal.content.state_code">
                  <option  v-for="listOfState in listOfStates" :key="listOfState.state" :value="listOfState.state_code">{{ listOfState.state }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="zipCode" class="form-label">Zip Code <sup class="text-danger">*</sup> </label>
                <input id="zipCode" type="text" class="form-control" readonly maxlength="5" name="infoModal.content.zip" v-model="infoModal.content.zip">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="homePhone" class="form-label">Phone  <sup class="text-danger">*</sup> </label>
                <vue-tel-input :inputOptions="options" disabled v-model="infoModal.content.home_phone" ref="phone" maxlength="20" v-bind="homeProps" :validCharactersOnly="true"></vue-tel-input>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="certificateFor" class="form-label">This certificate is for:<sup class="text-danger">*</sup> </label>
                <div class="form-group ">
                  <label class="form-label" for="yearRoundclubactivity"> <input type="radio" disabled id="yearRoundclubactivity"  name="infoModal.content.certificate_type" v-model="infoModal.content.certificate_type" value="year"> Year - Round Club Activity</label>
                  <label class="form-label" for="specificEvent">
                    <input type="radio" id="specificEvent" maxlength="20" disabled  name="infoModal.content.certificate_type" v-model="infoModal.content.certificate_type" value="specificEvent"> Specific Event</label><br>

                </div>
              </div>

            </div>
            <div class="col-md-12">
              <div class="form-group certificatefor" v-if="infoModal.content.certificate_type == 'specificEvent'">
                <span class="font-13 text-danger"><i>Note: Certificate for Specific sanctioned events will not be issued until the event is sanctioned and approved by USA Water Ski & Wake Sports.</i></span><br>
                <label for="eventType" class="form-label">Specific Event Title<sup class="text-danger">*</sup> </label>
                <input type="text"  class="form-control" readonly maxlength="45" name="infoModal.content.specific_event_title" v-model="infoModal.content.specific_event_title">
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group specificeventtype" v-if="infoModal.content.certificate_type == 'specificEvent'">
                <label for="eventType" class="form-label">Specific Sanctioned Event Type <sup class="text-danger">*</sup></label>
                <div class="form-group row">
                  <label class="form-label" for="tournament"><input type="radio" disabled id="tournament" name="infoModal.content.sanctioned_type" v-model="infoModal.content.sanctioned_type" value="tournament"> Tournament</label>
                  <label class="form-label" for="practice"><input type="radio" disabled id="practice" name="infoModal.content.sanctioned_type" v-model="infoModal.content.sanctioned_type" value="practice"> Practice</label><br>
                  <label class="form-label" for="basicskillclinic"><input type="radio" disabled id="basicskillclinic" name="infoModal.content.sanctioned_type" v-model="infoModal.content.sanctioned_type" value="basicskillclinic"> Basic Skills Clinic</label>
                  <label class="form-label" for="exhibition"><input type="radio" disabled id="exhibition" name="infoModal.content.sanctioned_type" v-model="infoModal.content.sanctioned_type" value="exhibition"> Exhibition</label><br>
                  <div v-show="infoModal.content.sanctioned_type == 'tournament'">
                    <p class="font-13 mb-1">Note: Certificates for specific sanctioned events will not be issued until the event is sanctioned and approved by USA-WSWS.</p>
                  </div>
                  <div v-show="infoModal.content.sanctioned_type == 'practice'">
                    <p class="font-13 mb-1">Note: Certificates for specific sanctioned events will not be issued until the event is sanctioned and approved by USA-WSWS.</p>
                  </div>
                  <div v-show="infoModal.content.sanctioned_type == 'basicskillclinic'">
                    <p class="font-13 mb-1">Note: Certificates for specific sanctioned events will not be issued until the event is sanctioned and approved by USA-WSWS.</p>
                  </div>
                  <div v-show="infoModal.content.sanctioned_type == 'exhibition'">
                    <p class="font-13 mb-1">Note: Certificates for specific sanctioned events will not be issued until the event is sanctioned and approved by USA-WSWS.</p>
                  </div>
                  <span class="text-sm text-danger">{{ insuranceErrors.sanctioned_type }}</span>
                </div>
                <div class="row" v-if="infoModal.content.certificate_type == 'specificEvent'">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="eventType" class="form-label">Event Sanction#  <sup class="text-danger">*</sup></label>
                      <input id="eventSanction" type="text" name="infoModal.content.event_sanction" maxlength="45" v-model="infoModal.content.event_sanction" class="form-control" readonly>
                    </div>
                  </div>
                  <div class="row">
                  <div class="col-md-6">
                    <div class="form-group insurence-datepicker">
                      <!-- <label for="eventType" class="form-label">Specific Events Start Date </label><br>

                      <date-pick
                          class=""
                          v-model="infoModal.content.start_date"
                          :pickTime="false"
                          name="infoModal.content.start_date"
                          :selectableYearRange="{from: 1900, to: 2021}"
                          :format="'MM/DD/YYYY'"
                          v-mask="'##/##/####'"
                          :isDateDisabled="isFutureDate"
                          placeholder="MM/DD/YYYY"
                          :parseDate="parseDatePick"
                          readonly
                      ></date-pick> -->
                       <label for="start_date" class="form-label">Specific Events Start Date <sup class="text-danger">*</sup></label><br>

                          <input
                              class="form-control"
                              id="start_date"
                              v-model="infoModal.content.start_date"
                              :pickTime="false"
                              name="infoModal.content.start_date"
                              @input="specialEventDateValidation(infoModal.content.start_date)"
                              :selectableYearRange="{from: minYear, to: teamExpYear}"
                              :format="'MM/DD/YYYY'"
                              v-mask="'##/##/####'"
                              :isDateDisabled="isFutureDate"
                              placeholder="YYYY/MM/DD"
                              readonly
                          >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group insurence-datepicker">
                      <!-- <label for="eventType" class="form-label">Specific Events End Date </label><br>

                      <date-pick
                          class=""
                          v-model="infoModal.content.end_date"
                          :pickTime="false"
                          name="infoModal.content.end_date"
                          :selectableYearRange="{from: 1900, to: 2021}"
                          :format="'YYYY/MM/DD'"
                          v-mask="'##/##/####'"
                          :isDateDisabled="isFutureDate"
                          placeholder="YYYY/MM/DD"
                          :parseDate="parseDatePick"
                          readonly
                      ></date-pick> -->

                       <label for="end_date" class="form-label">Specific Events End Date<sup class="text-danger">*</sup></label><br>

                          <input
                              class="form-control"
                              id="end_date"
                              v-model="infoModal.content.end_date"
                              :pickTime="false"
                              name="infoModal.content.end_date"
                              @input="specialEventEndDateValidation(infoModal.content.end_date)"
                              :selectableYearRange="{from: minYear, to: teamExpYear}"
                              :format="'MM/DD/YYYY'"
                              v-mask="'##/##/####'"
                              :isDateDisabled="isFutureDate"
                              placeholder="MM/DD/YYYY"
                              readonly
                          >
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ">
                <label class="form-label">Has the club entered into any agreement, contract, or permit which contains assumption of liability, indemnification, or hold harmless language? <sup class="text-danger">*</sup></label>
                <div class="">
                  <label class="form-label" for="agreeyes"><input type="radio" disabled id="agreeyes" name="infoModal.content.team_has_any_agreement" v-model="infoModal.content.team_has_any_agreement" value="Y"> Yes</label><br>

                </div>
                <div class="">
                  <label class="form-label" for="agreeno"> <input type="radio" disabled id="agreeno" name="infoModal.content.team_has_any_agreement" v-model="infoModal.content.team_has_any_agreement" value="N"> No</label><br>
                </div>
                <span class="font-13 text-danger"><i> (A copy of the document should be forwarded to USA-WSWS)</i></span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ">
                <label class="form-label">Does the certificate holder require additional insured status? <sup class="text-danger">*</sup></label>
                <div class="">
                  <label class="form-label" for="ins-status-yes"><input type="radio" disabled id="ins-status-yes" name="infoModal.content.additional_insurance" v-model="infoModal.content.additional_insurance" value="Y"> Yes</label><br>

                </div>
                <div class="">
                  <label class="form-label" for="ins-status-no"> <input type="radio" disabled id="ins-status-no" name="infoModal.content.additional_insurance" v-model="infoModal.content.additional_insurance" value="N"> No</label><br></div>
              </div>
              <div class="form-group " v-show="infoModal.content.additional_insurance=='Y'">
                <b-form-group label="Please check the relationship of the additional insured:">
                  <b-form-checkbox-group
                      id="additional_insurance_member"
                      v-model="infoModal.content.additional_insurance_member"
                      :options="additionalInsuranceMemberOptions"
                      name="additional_insurance_member"
                      disabled
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="ski_sites_modal"
      ref="modal"
      :ok-title="skiSiteAct"
      centered
      title="Site Information"
      @show="resetModalClubSite"
      @hidden="resetModalClubSite"
      @ok="handleOkClubSite"
      size="lg"
    >
      <form  @submit.stop.prevent="handleOkClubSite">
        <div class="form-body row">
          <input type="hidden" v-model="skiSites.extId" name="skiSitesId" />
          <div class="col-md-6">
            <div class="form-group">
              <label for="school" class="form-label"
                >Specific site/body of water of all club activities
                <i class="text-danger">*</i></label
              >
              <input
                type="text"
                class="form-control"
                maxlength="45"
                id="school"
                aria-describedby="school"
                placeholder="Enter Specific site"
                v-model="skiSites.school"
              />
              <span class="text-sm text-danger">{{
                skiSitesErrors.school
              }}</span>
            </div>
          </div>
        <div class="col-md-6">
          <div class="form-check mt-4">
            <input type="checkbox" id="membership_sign_social" name="form.membership_sign_social" class="form-check-input" v-model="skiSites.is_primary" value="1">
            <label for="membership_sign_social" class="form-check-label font-13">This site is the clubs primary/home ski site.</label>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="address" class="form-label">Physical Address</label>
            <input
              type="text"
              class="form-control"
              maxlength="45"
              id="address"
              v-model="skiSites.address"
            />
          </div>
        </div>
        <div  class="col-md-6">
          <div  class="form-group">
            <label for="address" class="form-label">Address 2</label>
            <input  type="text" maxlength="45" id="address2"  v-model="skiSites.address2" name="address.address2" class="form-control">
          </div>
        </div>

          <!-- <div class="col-md-6">
            <div class="form-group">
              <label for="country" class="form-label"
                >Country <i class="text-danger">*</i></label
              >

              <select
                class="form-control"
                id="country"
                name="address.country"
                v-model="skiSites.country"
              >
                <option value="">Select</option>
                <option
                  :value="listOfCountry.country"
                  v-for="listOfCountry in listOfCountries"
                  :key="listOfCountry.country"
                  >{{ listOfCountry.country }}</option
                >
              </select>
              <span class="text-sm text-danger">{{
                skiSitesErrors.country
              }}</span>
            </div>
          </div> -->
          <div class="col-md-6">
            <div class="form-group">
              <label for="city" class="form-label"
                >City <i class="text-danger">*</i></label
              >
              <input
                type="text"
                class="form-control"
                maxlength="45"
                id="city"
                name="form.city"
                v-model="skiSites.city"
              />
              <span class="text-sm text-danger">{{ skiSitesErrors.city }}</span>
            </div>
          </div>
          <div class="col-md-6" >
            <div class="form-group">
              <label for="state" class="form-label"
                >State <i class="text-danger">*</i></label
              >
              <select
                class="form-control"
                id="state"
                name="form.state_code"
                v-model="skiSites.state_code"
              >
                <option value="">Select</option>
                <option
                  v-for="listOfState in listOfStates"
                  :key="listOfState.state"
                  :value="listOfState.state_code"
                  >{{ listOfState.state }}</option
                >
              </select>
              <span class="text-sm text-danger">{{
                skiSitesErrors.state_code
              }}</span>
            </div>
          </div>
          <!-- <div class="col-md-6" v-else>
            <div class="form-group">
              <label for="state" class="form-label"
                >Other State <sup class="text-danger">*</sup></label
              >
              <input
                type="text"
                class="form-control"
                maxlength="45"
                v-model="skiSites.state"
              />
              <span class="text-sm text-danger">{{
                skiSitesErrors.state
              }}</span>
            </div>
          </div> -->

          <div class="col-md-6">
            <div class="form-group">
              <label for="zip" class="form-label"
                >Zip/Postal Code <i class="text-danger">*</i></label
              >
              <input
                type="text"
                class="form-control"
                :maxlength="getSiteProps('general.zip_code_length')"
                id="zip"
                name="form.zip"
                v-model="skiSites.zip"
              />
              <span class="text-sm text-danger">{{ skiSitesErrors.zip }}</span>
            </div>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="profile_pic_modal"
      size="md"
      ref="modal"
      ok-title="Upload Photo"
      centered
      title="Photo"
      @show="resetModal"
      @hidden="resetModal"
      @ok="updateLogoInfo"
    >
      <div class="form-body">
        <b-form-file
          v-model="uploadLogoFile"
          :state="Boolean(uploadLogoFile)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          v-on:change="onImageChange"
          accept=".jpg, .png, .gif"
        ></b-form-file>
        <div class="mt-3">
          Selected file:
          <img :src="getPreview()" alt="aa" style="height:177px;width:177px;" />
        </div>
      </div>
    </b-modal>

    <div class="personalinfo-modal">
      <b-modal id="modal-center1" size="lg" ok-title="Update" @ok="updateClubInfo" centered :title="prop_fields.club_info.tab_title || 'Club Information'" >
        <div class="form-body">
          <form>
            <div class="row">
              <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.clubname">
                <div class="form-group">
                  <label class="form-label" for="clubname">{{prop_fields.club_info.clubname.label || 'Club Name'}} <sup class="text-danger">*</sup></label>
                  <input type="text" id="clubname" v-model="UpdateCLub.name" :placeholder="prop_fields.club_info.clubname.placeholder||'Castro Valley Ski Club'" class="form-control" />
                  <span class="text-sm text-danger">{{ errors.name }}</span>
                </div>
              </div>
              <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.include_directory">
                <label class="form-label" for=""></label>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="directoryYes" :value="1" v-model="UpdateCLub.exclude" name="form.exclude">
                  <label class="form-check-label" for="directoryYes">Include in USA-WSWS online club directory</label>
                </div>
              </div>
              <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.primary_club_type">
                <div class="form-group">
                  <label class="form-label" for="clubtype">Primary Club Type <sup class="text-danger">*</sup></label>
                  <select
                    v-model="UpdateCLub.type"
                    id="clubtype"
                    class="form-control"
                  >
                    <option
                      v-for="(typ, val) in clubType"
                      :value="val"
                      :key="val"
                      >{{ typ }}</option
                    >
                  </select>
                  <span class="text-sm text-danger">{{ errors.type }}</span>
                </div>
              </div>
              <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.secondary_club_type">
                <div class="form-group">
                  <label class="form-label" for="clubtype">Secondary Club Type</label>
                  <select
                    v-model="UpdateCLub.secondary_type"
                    id="secondary_type"
                    class="form-control"
                  >
                    <option
                      v-for="(typ, val) in clubType"
                      :value="val"
                      :key="val"
                      >{{ typ }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_website">
                <div class="form-group">
                  <label class="form-label" for="clubwebsite">Club Website</label>
                  <input
                    type="text"
                    id="clubwebsite"
                    v-model="UpdateCLub.website"
                    placeholder="clubnew.com"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.email">
                <div class="form-group">
                  <label for="email" class="form-label">Club E-mail <sup class="text-danger">*</sup></label>
                  <input type="text" class="form-control" maxlength="45" id="email" v-model="UpdateCLub.email" name="form.email">
                  <span class="text-sm text-danger">{{ errors.email}}</span>
                </div>
              </div>
              <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_address">
                <div class="form-group">
                  <label for="club_address" class="form-label">Club Mailing Address <sup class="text-danger" v-if="prop_fields.club_info.club_address.required">*</sup></label>
                  <input type="text" class="form-control" maxlength="45" id="club_address" name="form.address" v-model="UpdateCLub.address">
                  <span class="text-sm text-danger">{{ errors.club_info_address}}</span>
                </div>
              </div>
              <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_city">
                <div class="form-group">
                  <label for="club_city" class="form-label">City <sup class="text-danger" v-if="prop_fields.club_info.club_city.required">*</sup></label>
                  <input type="text" class="form-control" maxlength="45" id="club_city" name="form.city" v-model="UpdateCLub.city">
                  <span class="text-sm text-danger">{{ errors.club_info_city}}</span>
                </div>
              </div>
              <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_state_code">
                <div class="form-group">
                  <label for="club_state" class="form-label">State <sup class="text-danger" v-if="prop_fields.club_info.club_state_code.required">*</sup></label>
                  <select class="form-control" id="club_state" name="form.state_code" v-model="UpdateCLub.state_code">
                    <option value="">Select</option>
                    <option  v-for="listOfState in listOfStates" :key="listOfState.state" :value="listOfState.state_code">{{ listOfState.state }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.club_info_state_code }}</span>
                </div>
              </div>
              <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_zip">
                <div class="form-group">
                  <label for="club_zip" class="form-label">Zip/Postal Code <sup class="text-danger" v-if="prop_fields.club_info.club_zip.required">*</sup></label>
                  <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" id="club_zip"  name="form.zip" v-model="UpdateCLub.zip">
                  <span class="text-sm text-danger">{{ errors.club_info_zip}}</span>
                </div>
              </div>
              <!--- Shooting --->
              <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_visible_type">
                  <div class="form-group">
                      <label for="club_visible_type" class="control-label">{{prop_fields.club_info.club_visible_type.label || 'Club Type'}}<sup class="text-danger" v-if="prop_fields.club_info.club_visible_type.required">*</sup></label>
                      <select name="UpdateCLub.club_visible_type" id="club_visible_type" class="form-control" v-model="UpdateCLub.visibility_type">
                          <option value="">Select</option>
                          <option v-for="(item, k) in prop_fields.club_info.club_visible_type.choices" :key="'club_visible_type'+k">{{ item }}</option>
                      </select>
                      <span class="text-sm text-danger">{{ errors.club_visible_type }}</span>
                  </div>
              </div>
              <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_type">
                  <div class="form-group">
                      <label for="club_type" class="control-label">{{prop_fields.club_info.club_type.label || 'Club Type'}}<sup class="text-danger" v-if="prop_fields.club_info.club_type.required">*</sup></label>
                      <Select2 v-if="prop_fields.club_info.club_type.multiple" v-model="UpdateCLub.club_type" name="form.club_type" id="club_type" :options="prop_fields.club_info.club_type.choices" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                      <select name="form.club_type" id="club_type" class="form-control" v-model="UpdateCLub.club_type" v-else>
                          <option value="">Select</option>
                          <option v-for="(item, k) in prop_fields.club_info.club_type.choices" :key="'club_type'+k">{{ item }}</option>
                      </select>
                      <span class="text-sm text-danger">{{ errors.club_type }}</span>
                  </div>
              </div>
              <div class="col-md-12" v-if="prop_fields.club_info && prop_fields.club_info.club_discipilines">
                  <div class="form-group discipilineitem">
                      <label for="" class="form-label">{{ prop_fields.club_info.club_discipilines.label || 'Club Disciplines' }}<sup class="text-danger" v-if="prop_fields.club_info.club_discipilines">*</sup></label>
                      <div class="row mb-2">
                          <div class="col-md-12">
                              <div class="form-check">
                                  <input class="form-check-input club_discipilines_check" type="checkbox" id="clubdisciplines-all"
                                  name="club_disciplines_all" @change="triggerSelectDisciplinesAll">
                                  <label class="d-flex mr-1" for="clubdisciplines-all">Select all that apply</label>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-md-4" v-for="(name, id) in clubDisciplinesList" :key="'cd_'+id">
                              <div class="form-check">
                                  <input class="form-check-input club_discipilines_check" type="checkbox" :id="'clubdisciplines-'+id" name="form.club_disciplines" :value="id" v-model="UpdateCLub.club_disciplines">
                                  <label class="d-flex mr-1" :for="'clubdisciplines-'+id">{{ name }}</label>
                              </div>
                          </div>
                      </div>
                      <span class="text-sm text-danger">{{ errors.club_discipilines }}</span>
                  </div>
              </div>
              <div class="col-md-12" v-if="prop_fields.club_info && prop_fields.club_info.club_admin_member_id">
                  <div class="form-group">
                      <label for="clubname" class="form-label">{{ prop_fields.club_info.club_admin_member_id.label }}  <sup class="text-danger" v-if="prop_fields.club_info.club_admin_member_id.required">*</sup></label>
                      <div class="input-group">
                          <input type="number" class="form-control" maxlength="45" id="clubname" aria-describedby="club_admin_member_id" name="form.club_admin_member_id" v-model="UpdateCLub.club_admin_member_id">
                          <div class="input-group-append">
                              <button class="btn btn-outline-primary" type="button" @click="searchMember"><i class="fa fa-search"></i> Search</button>
                          </div>
                      </div>
                      <span class="text-sm text-danger">{{ errors.club_admin_member_id }}</span>
                  </div>
                  <label class="text-danger" v-if="clubAdminSearch.searched && UpdateCLub.club_admin_name == ''">No Member Found with Member ID #{{ clubAdminSearch.memberIDSearched }}</label>
              </div>
              <div class="col-md-12" v-if="prop_fields.club_info && prop_fields.club_info.club_admin_name">
                  <div class="form-group">
                      <label for="clubname" class="form-label">{{ prop_fields.club_info.club_admin_name.label }}  <sup class="text-danger" v-if="prop_fields.club_info.club_admin_name.required">*</sup></label>
                      <input type="text" class="form-control" maxlength="45" id="clubname" aria-describedby="club_admin_name" name="form.club_admin_name" v-model="UpdateCLub.club_admin_name" :readonly="prop_fields.club_info.club_admin_name.readonly" :placeholder="prop_fields.club_info.club_admin_name.placeholder">
                      <span class="text-sm text-danger">{{ errors.club_admin_name }}</span>
                  </div>
              </div>
              <!--- Shooting --->
              <!-- Alliance fastpitch -->
              <div :class="'col-md-6'" v-if="prop_fields.club_info.age_division">
                <div class="form-group">
                  <label for="age_division" class="form-label">{{ prop_fields.club_info.age_division.label }} <sup class="text-danger" v-if="prop_fields.club_info.age_division.required">*</sup></label>
                  <select class="form-control" id="age_division" v-model="clubInfo.ageDivision.id" name="UpdateCLub.age_division">
                    <option value="">Select</option>
                    <option  v-for="(listOfAgeDivision,key) in ageDivisionOPt" :key="key"  :name="listOfAgeDivision.text" :value="listOfAgeDivision.id">{{ listOfAgeDivision.text }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.age_division}}</span>
                </div>
              </div>
              <div :class="'col-md-6'" v-if="prop_fields.club_info.member_league">
                <div class="form-group">
                  <label for="member_league" class="form-label">{{ prop_fields.club_info.member_league.label }} <sup class="text-danger" v-if="prop_fields.club_info.member_league.required">*</sup></label>
                  <input type="text" class="form-control" maxlength="100" id="member_league" v-model="clubInfo.memberLeageue.name" v-bind:readonly="true" name="UpdateCLub.member_league">
                  <span class="text-sm text-danger">{{ errors.member_league}}</span>
                </div>
              </div>
              <div :class="'col-md-6'" v-if="prop_fields.club_info.agl_team">
                <div class="form-group">
                  <label for="agl_team_id" class="form-label">{{ prop_fields.club_info.agl_team.label }} <sup class="text-danger" v-if="prop_fields.club_info.agl_team.required">*</sup></label>
                  <input type="text" class="form-control" maxlength="100" id="agl_team_id" v-model="UpdateCLub.agl_team_id" name="UpdateCLub.agl_team">
                  <span class="text-sm text-danger">{{ errors.agl_team_id}}</span>
                </div>
              </div>
              <!-- End -->
            </div>
          </form>
        </div>
      </b-modal>
    </div>

    <b-modal
      id="autoRenewalModal"
      ref="modal"
      hide-footer
      ok-title="Enable"
      centered
      title="Enable Auto Renewal"
      @show="resetCardModal"
      @hidden="resetCardModal"
      @cancel="resetRenewalStatus"
    >
    <template v-if="getSiteProps('general.paymentGateway') == 'stripe'">
      <div class="col-md-12">
          <div class="form-group mb-0">
              <label class="form-label mb-2">Card Number / Expiry Month and Year / CVC<sup class="text-danger">*</sup></label>
              <stripe-element-card
                  v-if="autoRenewalModalOpen"
                  ref="stripeForm"
                  :pk="stripeKey"
                  :hidePostalCode="true"
                  iconStyle="solid"
                  @token="stripeTokenCreated"
                  @error="captureStripeErrors"
                  @element-change="stripeFormChanges"
              />
              <p class="text-sm text-danger text-center" v-if="stripeError">{{ stripeError }}</p>
          </div>
      </div>
      </template>
      <template v-else>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-label"
                >Card Number<sup class="text-danger">*</sup></label
              >
              <input
                type="text"
                maxlength="16"
                placeholder="1234 1234 1234 1234"
                v-model="UpdCardInfo.cardNumber"
                class="form-control"
              />
              <small class="text-danger" v-html="cardError.cardNumber"></small>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="form-label"
              >Expiration Date<sup class="text-danger">*</sup></label
            >
            <select
              id="expiration-month"
              required="required"
              v-model="UpdCardInfo.expiration_month"
              class="form-control form-select"
            >
              <option value="">Month</option>
              <option>01</option>
              <option>02</option>
              <option>03</option>
              <option>04</option>
              <option>05</option>
              <option>06</option>
              <option>07</option>
              <option>08</option>
              <option>09</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
            </select>
            <small class="text-danger" v-html="cardError.expiration_month"></small>
          </div>
          <div class="col-md-4 pl-0 col-md-offset-1" style="margin-top: 28px;">
            <select
              id="expiration-year"
              required="required"
              v-model="UpdCardInfo.expiration_year"
              class="form-select form-control"
            >
              <option value="">Year</option>
              <option v-for="year in years" :value="year" :key="year">{{
                year
              }}</option>
            </select>
            <small class="text-danger" v-html="cardError.expiration_year"></small>
          </div>
          <div class="col-md-4">
            <label class="form-label"
              >CVV/CVC<sup class="text-danger">*</sup></label
            >
            <input
              maxlength="4"
              type="password"
              v-model="UpdCardInfo.cvv"
              class="form-control"
            />
            <small class="text-danger" v-html="cardError.cvv"></small>
          </div>
        </div>
      </template>
      <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="updateCreditCardInfo" :disabled="autoRenewProgress">
              Enable <i class="fa fa-circle-notch fa-spin" v-if="autoRenewProgress"></i>
          </button>
      </div>
    </b-modal>

    <b-modal id="edit-club-contact" size="xl" ok-title="Update" @ok="updateClubContact" centered title="Contact Information" >
      <div class="form-body" v-if="prop_fields_contact">
        <div class="row">
          <div class="col-md-6">
            <div class="row" v-if="prop_fields_contact">
              <div class="col-md-12" v-if="prop_fields_contact.email">
                  <div class="form-group">
                      <label for="email" class="form-label">{{ prop_fields_contact.email.label }} <sup class="text-danger" v-if="prop_fields_contact.email.required">*</sup></label>
                      <input type="text" class="form-control" maxlength="45" id="email" v-model="UpdateCLub.email" name="form.email">
                      <span class="text-sm text-danger">{{ errors.email}}</span>
                  </div>
              </div>
              <div class="col-md-12" v-if="prop_fields_contact.retype_email">
                  <div class="form-group">
                      <label for="retype_email" class="form-label">{{ prop_fields_contact.retype_email.label }} <sup class="text-danger" v-if="prop_fields_contact.retype_email.required">*</sup></label>
                      <input type="text" class="form-control" maxlength="45" id="retype_email" v-model="UpdateCLub.retype_email" name="form.retype_email">
                      <span class="text-sm text-danger">{{ errors.retype_email}}</span>
                  </div>
              </div>
              <div class="col-md-12" v-if="prop_fields_contact.homephone && !isNotalliancefastpitch">
                  <div class="form-group ">
                      <div class="phone-nu-wrapper">
                          <label for="country_code_h" class="form-label">{{ prop_fields_contact.homephone.label }} <sup class="text-danger" v-if="prop_fields_contact.homephone.required">*</sup></label>
                          <vue-tel-input v-if="!isNotalliancefastpitch" v-model="UpdateCLub.phone" ref="phone" @validate="validateTelinput"  v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                          <span class="text-sm text-danger">{{ errors.homephone }}</span>
                      </div>
                  </div>
              </div>
              <div class="col-md-12" v-if="prop_fields_contact.mobilephone">
                <div class="form-group ">
                  <div class="phone-nu-wrapper">
                    <label for="country_code_h" class="form-label">{{ prop_fields_contact.mobilephone.label }} <sup class="text-danger" v-if="prop_fields_contact.mobilephone.required">*</sup></label>
                    <vue-tel-input v-if="isNotalliancefastpitch" v-model="UpdateCLub.primary_contact.phone_1" ref="phone" @validate="validateTelinput"  v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                    <vue-tel-input v-if="!isNotalliancefastpitch" v-model="UpdateCLub.phone_2" ref="phone" @validate="validateTelinput2"  v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                    <span class="text-sm text-danger">{{ errors.mobilephone }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row" v-if="prop_fields_contact">
              <div class="col-md-12" v-if="clubAdditionalInfoView && prop_fields_contact.club_address">
                  <div class="form-group">
                      <label for="firstname" class="form-label">{{ prop_fields_contact.club_address.label }} <sup class="text-danger" v-if="prop_fields_contact.club_address.required">*</sup></label>
                      <input type="text" class="form-control" maxlength="100" id="club_address" v-model="UpdateCLub.primary_contact.address1" name="form.club_address">
                      <span class="text-sm text-danger">{{ errors.club_address }}</span>
                  </div>
              </div>
              <div class="col-md-4" v-if="clubAdditionalInfoView && prop_fields_contact.club_city">
                  <div class="form-group">
                      <label for="club_city" class="form-label">{{ prop_fields_contact.club_city.label }} <sup class="text-danger" v-if="prop_fields_contact.club_city.required">*</sup></label>
                      <input type="text" class="form-control" maxlength="45" id="club_city"  v-model="UpdateCLub.primary_contact.city" name="form.club_city">
                      <span class="text-sm text-danger">{{ errors.club_city }}</span>
                  </div>
              </div>
              <div class="col-md-4" v-if="clubAdditionalInfoView && prop_fields_contact.club_state">
                  <div class="form-group">
                      <label for="club_state" class="form-label">{{ prop_fields_contact.club_state.label }} <sup class="text-danger" v-if="prop_fields_contact.club_state.required">*</sup></label>
                      <select class="form-control" id="club_state" v-model="UpdateCLub.primary_contact.state_code" name="form.club_state" >
                          <option value="">Select</option>
                          <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                      </select>
                      <span class="text-sm text-danger">{{ errors.club_state }}</span>
                  </div>
              </div>
              <div class="col-md-4" v-if="clubAdditionalInfoView && prop_fields_contact.club_zip">
                  <div class="form-group">
                      <label for="club_zip" class="form-label">{{ prop_fields_contact.club_zip.label }} <sup class="text-danger" v-if="prop_fields_contact.club_zip.required">*</sup></label>
                      <input type="text" class="form-control" maxlength="15" id="club_zip" v-model="UpdateCLub.primary_contact.zip" name="form.club_zip">
                      <span class="text-sm text-danger">{{ errors.club_zip }}</span>
                  </div>
              </div>
              <div class="col-md-12" v-if="!clubAdditionalInfoView && prop_fields_contact.address1">
                <div class="form-group">
                  <label for="firstname" class="form-label">{{ prop_fields_contact.address1.label }} <sup class="text-danger" v-if="prop_fields_contact.address1.required">*</sup></label>
                  <input type="text" class="form-control" maxlength="100" id="address1" v-model="UpdateCLub.address" name="form.club_address">
                  <span class="text-sm text-danger">{{ errors.club_address }}</span>
                </div>
              </div>
              <div class="col-md-4" v-if="!clubAdditionalInfoView && prop_fields_contact.city">
                <div class="form-group">
                  <label for="club_city" class="form-label">{{ prop_fields_contact.city.label }} <sup class="text-danger" v-if="prop_fields_contact.city.required">*</sup></label>
                  <input type="text" class="form-control" maxlength="45" id="city"  v-model="UpdateCLub.city" name="form.club_city">
                  <span class="text-sm text-danger">{{ errors.club_city }}</span>
                </div>
              </div>
              <div class="col-md-4" v-if="!clubAdditionalInfoView && prop_fields_contact.city">
                <div class="form-group">
                  <label for="club_city" class="form-label">{{ prop_fields_contact.country.label }} <sup class="text-danger" v-if="prop_fields_contact.country.required">*</sup></label>
                  <select class="form-control" id="country" @change="removeStateCode" v-model="UpdateCLub.country" name="UpdateProfile.country">
                    <option value="">Select</option>
                    <option :value="listOfCountry.country" v-for="listOfCountry in prop_fields_contact.country.options" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.country}}</span>
                </div>
              </div>
              <div class="col-md-4" v-if="!clubAdditionalInfoView && prop_fields_contact.state_code">
                <div class="form-group">
                  <label for="club_state" class="form-label">{{ prop_fields_contact.state_code.label }} <sup class="text-danger" v-if="prop_fields_contact.state_code.required">*</sup></label>
                  <select class="form-control" id="state_code" v-model="UpdateCLub.state_code" name="form.club_state" v-if="UpdateCLub.country=='USA' || UpdateCLub.country==''">
                    <option value="">Select</option>
                    <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                  </select>
                  <select class="form-control" id="state_code" v-model="UpdateCLub.state_code" name="form.club_state" v-else>
                    <option value="">Select</option>
                    <option  v-for="listOfState in prop_fields_contact.state_code.option" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.club_state }}</span>
                </div>
              </div>
              <div class="col-md-4" v-if="!clubAdditionalInfoView && prop_fields_contact.zip">
                <div class="form-group">
                  <label for="club_zip" class="form-label">{{ prop_fields_contact.zip.label }} <sup class="text-danger" v-if="prop_fields_contact.zip.required">*</sup></label>
                  <input type="text" class="form-control" maxlength="15" id="zip" v-model="UpdateCLub.zip" name="form.club_zip">
                  <span class="text-sm text-danger">{{ errors.club_zip }}</span>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-12" v-if="prop_fields_contact.club_physical_address">
                  <div class="form-group">
                      <label for="club_physical_address" class="form-label">{{ prop_fields_contact.club_physical_address.label }} <sup class="text-danger" v-if="prop_fields_contact.club_physical_address.required">*</sup></label>
                      <div class="form-check mt-2 mb-3" v-if="prop_fields_contact.club_physical_address.same_check">
                          <input type="checkbox" id="same_as_mailing_address" name="form.same_as_mailing_address" class="form-check-input" value="1" v-model="UpdateCLub.same_as_mailing_address" @change="changeSameAddress">
                          <label for="same_as_mailing_address" class="d-flex mr-1">Same as Mailing Address</label>
                      </div>
                      <input type="text" class="form-control" maxlength="100" id="club_physical_address" v-model="UpdateCLub.primary_contact.physical_address" name="form.club_physical_address" :readonly="UpdateCLub.same_as_mailing_address">
                      <span class="text-sm text-danger">{{ errors.club_physical_address }}</span>
                  </div>
              </div>
              <div class="col-md-4" v-if="prop_fields_contact.club_physical_city">
                  <div class="form-group">
                      <label for="club_physical_city" class="form-label">{{ prop_fields_contact.club_physical_city.label }} <sup class="text-danger" v-if="prop_fields_contact.club_physical_city.required">*</sup></label>
                      <input type="text" class="form-control" maxlength="45" id="club_physical_city"  v-model="UpdateCLub.primary_contact.physical_city" name="form.club_physical_city" :readonly="UpdateCLub.same_as_mailing_address">
                      <span class="text-sm text-danger">{{ errors.club_physical_city }}</span>
                  </div>
              </div>
              <div class="col-md-4" v-if="prop_fields_contact.club_physical_state">
                  <div class="form-group">
                      <label for="club_physical_state" class="form-label">{{ prop_fields_contact.club_physical_state.label }} <sup class="text-danger" v-if="prop_fields_contact.club_physical_state.required">*</sup></label>
                      <select class="form-control" id="club_physical_state" v-model="UpdateCLub.primary_contact.physical_state_code" name="form.club_physical_state" :disabled="UpdateCLub.same_as_mailing_address">
                          <option value="">Select</option>
                          <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                      </select>
                      <span class="text-sm text-danger">{{ errors.club_physical_state }}</span>
                  </div>
              </div>
              <div class="col-md-4" v-if="prop_fields_contact.club_physical_zip">
                  <div class="form-group">
                      <label for="club_physical_zip" class="form-label">{{ prop_fields_contact.club_physical_zip.label }} <sup class="text-danger" v-if="prop_fields_contact.club_physical_zip.required">*</sup></label>
                      <input type="text" class="form-control" maxlength="15" id="club_physical_zip" v-model="UpdateCLub.primary_contact.physical_zip" name="form.club_physical_zip" :readonly="UpdateCLub.same_as_mailing_address">
                      <span class="text-sm text-danger">{{ errors.club_physical_zip }}</span>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="subdomain == 'shooting'">
          <div class="row form-group col-md-12">
            <label for="clubname" class="form-label"> Club Admin Individual USA Shooting Member ID #</label>
            <div class="input-group">
                <input type="number" class="form-control" maxlength="45" id="clubname" aria-describedby="club_admin_member_id" name="UpdateCLub.club_admin_member_id" v-model="UpdateCLub.club_admin_member_id">
                <div class="input-group-append">
                    <button class="btn btn-outline-primary" type="button" @click="searchMember"><i class="fa fa-search"></i> Search</button>
                </div>
            </div>
              <span class="text-sm text-danger" :key="errorUpdateKey">{{ errors.club_admin_member_id }}</span>
          </div>
            <label class="text-danger" v-if="clubAdminSearch.searched && UpdateCLub.club_admin_name == ''">No Member Found with Member ID #{{ clubAdminSearch.memberIDSearched }}</label>
            <div class="row form-group col-md-12">
                <label for="clubname" class="form-label">Club Admin Name <sup class="text-danger">*</sup></label>
                <input type="text" class="form-control" maxlength="45" id="clubname" aria-describedby="club_admin_name" name="UpdateCLub.club_admin_name" v-model="UpdateCLub.club_admin_name" readonly :placeholder="UpdateCLub.club_admin_name.placeholder">
                <span class="text-sm text-danger">{{ errors.club_admin_name }}</span>
            </div>
        </div>
      </div>
    </b-modal>

    <b-modal id="invitePlayer-modal" title="Invite Player and Staff" @ok="invitePlayer" @hidden="resetDisciplineData" ok-title="Submit">
      <div class="form-body">
        <form>
          <template>                     
            <label for="invite_user">Email</label>
            <br><em for="invite_user">Please type each email address fully and press enter to store the email address. Once all email ids have been entered please click Add and then click Send to generate emails</em>
            <b-form-tags
                input-id="invite_user"
                type="email"
                v-model="invite_players"
                placeholder=" "
                :tag-validator="emailValidator"
                tagClass="invite_tag"
                separator=" ,"
                @tag-state="onTagState"
            ></b-form-tags>
            <div v-if="errorMessage !=''" class="error-message text-danger">
              {{ errorMessage }}
            </div>
          </template>
        </form>
      </div>
    </b-modal>  

    <member-lookup v-on:member-lookup-data="ChangeClubMember"></member-lookup>
  </div>
</template>

<script>
import * as $ from "jquery";
import MemberLookup from "../components/MemberLookup";

import CustomAlert from "../../../CustomAlert";
import ClubCard from "./clubcard";
import moment from "moment";
import axios from "axios";

import { StripeElementCard } from "@vue-stripe/vue-stripe";

const validateName = (name, fieldName = '',isName = false) => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if(isName) {
    if (!names.match(/[A-Za-z? ( ) -]+$/)) {
      return {valid: false, error: fieldText + " must be letters only. No other characters"};
    }
  }
  return { valid: true, error: null };
};

const validateZip = (zipCode) => {
  if( zipCode == undefined || !zipCode.length || zipCode.trim() == "")
  {
    return { valid: false, error: "Zip code is required" };
  }
  else if(zipCode.length < 5 ){
         return { valid: false, error: "Zip code should be 5 character" };
  }
  else if(!zipCode.match(/^\d{5}(-\d{4})?$/)){
    return { valid: false, error: "Zip Code Should Be 5 Number Values." };
  }

  return { valid: true, error: null };
}

const validateNonMandatoryZip = (zipCode, fieldName = '') => {
  let zipText= (fieldName != '' ? fieldName : "Zip code");
  if(zipCode != undefined && zipCode.length && zipCode.trim() != "" && !zipCode.match(/^\d{5}(-\d{4})?$/)){
    return { valid: false, error: zipText+" Should Be 5 Number Values for USA." };
  }
  return { valid: true, error: null };
};

// Insurance
const validateInsuranceName = name => {
  if (name == undefined) {
    return { valid: false, error: "This field is required" };
  } else if(!name.length) {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
const validateRadio = radio => {
  if (!radio) {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
const validateSelect = select => {
    console.log('lenghth='+select);
    if (!select) {
        console.log('lenght3333h='+select);
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateCheck = choices => {
    if(choices.length) return { valid: true, error: null };
    return { valid: false, error: "This field is required" };
}
const validatePhone = (phone, validTelinput, phoneField= '' ) => {
    let phoneText = ( phoneField != '' ? phoneField : "This field");
    if (!phone) {
        return { valid: false, error: phoneText+' is required .' };
    }
    if(validTelinput == false && phone.length){
    return { valid: false, error: 'Enter the Exact phone number(Example:+XX XX XXXX XXXX).' };
    }
    return { valid: true, error: null };
}

const validateEmail = (email, fieldEmail='') => {
  let emailText= (fieldEmail != '' ? fieldEmail : "This field");
  if (!email.length) {
    return { valid: false, error: emailText+" is required" };
  }
  if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/) || !email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
    return { valid: false, error: "Please enter a valid email." };
  }
  return { valid: true, error: null };
};
const validatePhone2 = (phone, validTelinput2) => {
  if(validTelinput2 == false && phone.length){
    return { valid: false, error: 'Enter the Exact phone number(Example:+XX XX XXXX XXXX).' };
  }
  return { valid: true, error: null };
}
const validateAddressName = (name, contactField='' ) => {
  let contactText = ( contactField != '' ? contactField : "This field");
  if (!name.length) {
    return { valid: false, error: contactText+ " is required " };
  }
  return { valid: true, error: null };
};
export default {
  name: "clubInfo",
  components: { StripeElementCard, CustomAlert, MemberLookup, ClubCard },
  data() {
    return {
      isProfileloading:false,
      errorMessage: "",
      clubInfo: "",
      regDate: '',
      expiryDate: '',
      contactType: "",
      UpdateCLub: {
        type: "",
        name: "",
        school: "",
        description: "",
        address: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        website: "",
        secondary_type:"",
        state_code:"",
        exclude:"",
        email:"",

        retype_email: "",

        visibility_type: "",
        club_type: [],
        club_disciplines: [],
        club_admin_member_id: "",
        club_admin_name: "",
        same_as_mailing_address: "",
        primary_contact: {},
        phone:"",
        phone_2:"",
        memId: '',
      },
      errorUpdateKey:0,
      UpdatePrimary: {
        membership_id: "",
        contact_type: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_1: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        zip: "",
      },
      tblFields: [
        { key: 'membership_id', sortable: true, label: 'Member #' },
        {  key: 'first_name', sortable: true },
        { key: 'last_name', sortable: true },
        { key: 'city', sortable: true },
        { key: 'state', sortable: true },
        { key: 'country', sortable: true, label: 'Country' },
        { key: 'zip', sortable: false, label: 'Zip' },
        { key: 'actions', sortable: false },
      ],
      tblSiteFields: [
        {
          key: "school",
          sortable: true,
          label: "Specific site/body of water of all club activities",
        },
        { key: "primary_club", sortable: true, label: "Primary Site" },
        { key: "address", sortable: true, label: "Physical Address" },
        { key: "city", sortable: true },
        { key: "state_code", sortable: true, label: "State" },
        { key: "zip", sortable: true },
        { key: "actions", sortable: false },
      ],
      insuranceList: [],
      insuranceListKey: 0,
      viewInsurancePrevious: false,
      tblInsuredFields: [
        { key: 'company_name', sortable: true, label: 'Company Name' },
        { key: 'first_name', sortable: true },
        { key: 'last_name', sortable: true },
        { key: 'start_date', sortable: true, label: 'Start Date', formatter: 'usdate'},
        { key: 'end_date', sortable: true, label: 'End Date', formatter: 'usdate'},
        { key: 'transaction_code', sortable: true, label: 'Transaction Id' },
        { key: 'transaction_time', sortable: true, label: 'Purchased Date', formatter: 'usdate'},
        { key: 'actions', sortable: false },
      ],
      sanctionList: [],
      sanctionListKey: 0,
      viewSanctionPrevious: false,
      tblSanctionFields: [
        { key: 'sanction_number', sortable: true, label: 'Sanction Number' },
        { key: 'guest_registration_url', sortable: true, label: 'Guest Registration Url' },
        { key: 'cost', sortable: true, label: 'Cost' },
        { key: 'transaction_code', sortable: true, label: 'Transaction Id' },
        { key: 'registration.start_date', sortable: true, label: 'Start Date', formatter: 'usdate' },
        { key: 'registration.valid_thru', sortable: true, label: 'End Date', formatter: 'usdate' },
        { key: 'transaction_time', sortable: true, label: 'Purchased Date', formatter: 'usdate' },
        { key: 'waiver', sortable: false, label: 'Waiver'},
        { key: 'sanction', sortable: false, label: 'Sanction'},
      ],
      updateLoggedUserInfo: 0,
      uploadLogoFile: "",
      logo: "",
      preview: "",
      skiSiteAct: "Add Ski-Sites",
      skiSites: {
        school: "",
        address: "",
        address2:"",
        country: "",
        state: "",
        state_code: "",
        city: "",
        zip: "",
        extId: "",
      },
      skiSitesErrors: {
        school: "",
        country: "",
        state: "",
        state_code: "",
        city: "",
        zip: "",
      },
      autoRenew: 0,
      autoRenewData: {
        regId: "",
        status: "",
      },
      UpdCardInfo: {
        stripeToken: "",
        cardNumber: "",
        expiration_year: "",
        expiration_month: "",
        cvv: "",
      },
      prop_fields: {},
      prop_fields_contact: {},
      insuranceErrors: {},
      insuranceForm: {
        first_name: '',
        last_name: '',
        company_name: '',
        insurance_type: '',
        home_phone: '',
        home_phone_country_code: '',
        home_phone_extension: '',
        work_phone: '',
        work_phone_country_code: '',
        work_phone_extension: '',
        fax: '',
        address1: '',
        address2: '',
        city: '',
        state_code: '',
        state: '',
        zip: '',
        country: '',
        certificate_type: '',
        sanctioned_type: '',
        event_sanction: '',
        specific_event_title: '',
        start_date: '',
        end_date: '',
        team_has_any_agreement: '',
        additional_insurance: '',
        additional_insurance_member: [],
        index: '',
        team_id: '',
        registration_id: '',
      },
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      clubAdminSearch: {
          searched: false,
          memberIDSearched: ''
      },
      validTelinput: false,
      validTelinput2: false,
      homeProps: {
        mode: "international",
        defaultCountry: "US",
        inputOptions:{
          autocomplete:"on",
          autofocus:false,
          id:"",
          maxlength:20,
          name:"telephone",
          placeholder:"Home Number",
          readonly:false,
          required:false,
          showDialCode:false,
          styleClasses:"",
          tabindex:0,
          type:"tel"
        }

      },
      mobileProps: {
        mode: "international",
        defaultCountry: "US",
        inputOptions:{
          autocomplete:"on",
          autofocus:false,
          id:"",
          maxlength:20,
          name:"telephone",
          placeholder:"Mobile Number",
          readonly:false,
          required:false,
          showDialCode:false,
          styleClasses:"",
          tabindex:0,
          type:"tel"
        }
      },
      notACompany: false,
      refreshProgress: false,
      autoRenewalModalOpen: false,
      stripeToken: null,
      stripeError: "",
      stripeValidate: false,
      autoRenewProgress: false,
      cardError: {},
      sanctioningUrl: '',
      membershipExpired: false,
      insuranceUrl: "javascript:void(0)",       
      tblrosterFields: [                
          { key: 'first_name', sortable: true, label: 'First Name', sortByFormatted: true, formatter: (value, key, item) => item.personal_details.first_name   },
          { key: 'last_name', sortable: true, label: 'Last Name',sortByFormatted: true, formatter: (value, key, item) => item.personal_details.last_name  },
          { key: 'confirmation_code', sortable: true, label: 'Member #' },
          { key: 'membership_name', sortable: true, label: "Membership Type",sortByFormatted: true, formatter: (value, key, item) => item.latest_registration.membership.name },
          { key: 'membership_endtate', sortable: true, label: "Membership Expiration",sortByFormatted: true, formatter: (value, key, item) => item.latest_registration.valid_thru },
          { key: 'member_status', sortable: true, label: 'Membership Status',sortByFormatted: true, formatter: (value, key, item) => item.status_text },
          //{ key: 'actions', sortable: false },
      ],
      tblinvitePlayerFields: [                
          { key: 'email', sortable: false, label: 'Email'  },
          { key: 'flag', sortable: false, label: 'Status'  },
          { key: 'actions', sortable: false },
      ],
      invite_players:'',
      file: null,
      btnloading: false,
      clubAdditionalInfoView: true,
      ageDivisionOPt:[
        {"id":"4","text":"12U"},
        {"id":"5","text":"10U"}
      ],
      disciplines:[],
      disciplines_list:[],
      isNotalliancefastpitch:true,
      alertMessage: "",
      displayAlert: "",
      displayMissingMemFields: false,
    };
  },
  methods: {
    formatDate(value)
    {
      if(value){
        return moment(value).format('MM/DD/YYYY');
      } else {
        return 'N/A';
      }
    },
    downloadDocs(item, type) {
      this.downloadPdf(this.basePath + 'api/club/sanction-download/'+type+'/'+item, 'Reg'+item+"_"+type );
    },
    downloadPdf(url, name){
      axios.get(url, {headers:this.clubHeaders, responseType: 'arraybuffer'})
          .then(response => {
            let blob = new Blob([response.data], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = this.clubInfo.club_name+"_"+name+'.pdf';
            link.click();
            link.remove();
          }).catch((error)=>{
        console.log(error);
      });
    },
    handleInsuranceOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleInsuranceSubmit()
    },
    handleInsuranceSubmit() {
      // Exit when the form isn't valid
      if (!this.insuranceValidate()) {
        return;
      }
      this.addInsurance();
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-center6')
      })
    },
    insuranceValidate() {
      this.errors = {}
      this.valid = false;
      console.log('------validate-------');
      console.log(this.insuranceForm);
      const validFirstName = validateInsuranceName(this.insuranceForm.first_name);
      const validLastName = validateInsuranceName(this.insuranceForm.last_name);
      const validCompanyName = validateInsuranceName(this.insuranceForm.company_name);
      const validAddress1 = validateInsuranceName(this.insuranceForm.address1);
      const validStateCode = validateInsuranceName(this.insuranceForm.state_code);
      const validCity = validateInsuranceName(this.insuranceForm.city);
      const validZip = validateInsuranceName(this.insuranceForm.zip);
      //const validHomePhoneCode = validateName(this.insuranceForm.home_phone_country_code);
      const validHomePhone = validateInsuranceName(this.insuranceForm.home_phone);
      const validCertificateType = validateInsuranceName(this.insuranceForm.certificate_type);
      const validSpecificEventTitle = validateInsuranceName(this.insuranceForm.specific_event_title);
      const validEventSanctionStartDate = validateName(this.insuranceForm.start_date);
      const validEventSanctionEndDate = validateName(this.insuranceForm.end_date);
      const validSanctionedType = validateRadio(this.insuranceForm.sanctioned_type);
      const validEventSanctionNo = validateName(this.insuranceForm.event_sanction);
      const validTeamHasAnyAgreement = validateRadio(this.insuranceForm.team_has_any_agreement);
      const validAdditionalInsurance = validateRadio(this.insuranceForm.additional_insurance);
      const validNotACompany = validateCheck(this.notACompany);

      this.insuranceErrors.first_name = validFirstName.error; //'Please Enter First Name';
      this.insuranceErrors.last_name = validLastName.error; //'Please Enter Last Name';
      this.insuranceErrors.company_name = validCompanyName.error;//'Please Company Name';
      this.insuranceErrors.address1 = validAddress1.error;
      this.insuranceErrors.state_code = validStateCode.error;
      this.insuranceErrors.city = validCity.error;
      this.insuranceErrors.zip = validZip.error;
      //this.errors.home_phone_country_code = validHomePhoneCode.error;
      this.insuranceErrors.home_phone = validHomePhone.error;
      this.insuranceErrors.certificate_type = validCertificateType.error;
      this.insuranceErrors.specific_event_title = validSpecificEventTitle.error;
      this.insuranceErrors.sanctioned_type = validSanctionedType.error;
      this.insuranceErrors.event_sanction = validEventSanctionNo.error;
      this.insuranceErrors.team_has_any_agreement = validTeamHasAnyAgreement.error;
      this.insuranceErrors.additional_insurance = validAdditionalInsurance.error;

      /*if(validFirstName.valid || validLastName.valid) {
        this.insuranceErrors.company_name = '';
      }
      if(validCompanyName.valid) {
        this.insuranceErrors.first_name = '';
        this.insuranceErrors.last_name = '';
      }*/
      if(this.insuranceForm.certificate_type == 'year') {
        this.insuranceErrors.sanctioned_type = '';
      }
      if ((validCompanyName.valid || (validFirstName.valid && validLastName.valid)) && validAddress1.valid
          && validStateCode.valid && validCity.valid && validZip.valid && validHomePhone.valid
          && validCertificateType.valid
          && validTeamHasAnyAgreement.valid && validAdditionalInsurance.valid) {
        this.valid = true;
        if(this.notACompany && (!validFirstName.valid || !validLastName.valid)) {
          this.valid = false;
        } else if(!this.notACompany && !validCompanyName.valid) {
          this.valid = false;
        }
        if(this.insuranceForm.certificate_type == 'specificEvent' && (!validSpecificEventTitle.valid || !validSanctionedType.valid || !validEventSanctionNo.valid || !validEventSanctionStartDate.valid || !validEventSanctionEndDate.valid)) {
          this.valid = false;
        } else {
          if(validEventSanctionStartDate.valid && !this.validateDate(this.insuranceForm.start_date)) {
            this.valid = false;
            this.insuranceErrors.start_date = "Invalid Date";
          }
          if(validEventSanctionEndDate.valid && !this.validateDate(this.insuranceForm.end_date)) {
            this.valid = false;
            this.insuranceErrors.end_date = "Invalid Date";
          }
        }
        if(this.valid) {
          this.errors = {}
          this.insuranceErrors = {}
        }
      }
      return this.valid;
    },
    handleOkClubSite(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleClubSite()
      },
     handleClubSite() {
            // Exit when the form isn't valid
            if (!this.skiSiteValidate()) {
                return;
            }
            console.log(this.skiSites);
            axios
          .post(this.basePath + "api/club/ski_site", this.skiSites, {
            headers: this.clubHeaders,
          })
          .then(
              function(response) {
                this.alertMessage = "Club Site Added successfully";
                this.displayAlert = true;
              }.bind(this)
          )
          .catch(function(error) {
            console.log(error);
          });

            this.resetModalClubSite();
            this.$nextTick(() => {
                this.$bvModal.hide('ski_sites_modal')
            })
        },
      resetModalClubSite() {
          this.address = {
              school:'',
              is_primary:'',
              address:'',
              address2:'',
              city:'',
              state_code:'',
              zip:''
          }
      },
    viewRow(item, index, button) {
      this.infoModal.title = 'Certificate(s) of Insurance';
      this.infoModal.content = Object.assign({}, item);
      this.infoModal.content.start_date = moment(this.infoModal.content.start_date).format("MM/DD/YYYY");
      this.infoModal.content.end_date = moment(this.infoModal.content.end_date).format("MM/DD/YYYY");
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    deleteInsurance(i) {
      this.displayAlert = false;
      this.$bvModal
          .msgBoxConfirm(
              "Please confirm that you want to delete this Insurance",
              {
                title: "Please Confirm",
                size: "md",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "YES",
                cancelTitle: "NO",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
              }
          )
          .then((value) => {
                if (String(value) == "true") {
                  console.log("remove index=" + i);
                  let removeInsurance = {};
                  removeInsurance.insurance_id = i;
                  axios
                      .post(this.basePath + "api/remove_club_insurance", removeInsurance, {
                        headers: this.clubHeaders,
                      })
                      .then(
                          function (response) {
                            this.alertMessage = "Club Insurance deleted successfully";
                            this.displayAlert = true;
                            this.loadClubInfo();
                          }.bind(this)
                      )
                      .catch(function (error) {
                        console.log(error);
                      });
                }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    addInsurance() {
      this.displayAlert = false;
      this.insuranceForm.team_id = this.clubInfo.id;
      this.insuranceForm.registration_id = this.clubInfo.registration.id;
      axios
          .post(this.basePath + "api/add_club_insurance", this.insuranceForm, {
            headers: this.clubHeaders,
          })
          .then(
              function(response) {
                this.alertMessage = "Club Insurance added successfully";
                this.displayAlert = true;
                this.loadClubInfo();
              }.bind(this)
          )
          .catch(function(error) {
            console.log(error);
          });
    },
    loadClubInfo() {
      this.isProfileloading = true;
      axios
        .get(this.basePath + "api/club/info", { headers: this.clubHeaders })
        .then((response) => {
          this.isProfileloading = false;
          const resp = response.data.data;
          this.clubInfo = resp;
          this.regDate = moment(this.clubInfo.reg_date).format('MM/DD/YYYY');
          this.expiryDate = moment(this.clubInfo.expires_at).format('MM/DD/YYYY');
          this.autoRenewData.regId = resp.registration.id;
          this.autoRenewData.status = resp.auto_renew;moment(this.clubInfo.expires_at).format('MM/DD/YYYY');
          this.autoRenew = resp.auto_renew;
          this.loadTeamInsurances();
          this.loadClubSanctions();
          for (let prop in resp) {
            if (Object.prototype.hasOwnProperty.call(this.UpdateCLub, prop)) {
              this.UpdateCLub[prop] = resp[prop];
            }
            if (prop == "primary_contact") {
              for (let prp in resp[prop]) {
                this.UpdateCLub[prop][prp] = resp[prop][prp];
                if (
                  Object.prototype.hasOwnProperty.call(this.UpdatePrimary, prp)
                ) {
                  this.UpdatePrimary[prp] = resp[prop][prp];
                }
              }
            }
          }
          this.UpdateCLub.memId = this.UpdateCLub.club_admin_member_id;
          this.UpdateCLub.name = resp.club_name;
          this.UpdateCLub.agl_team_id = resp.agl_team_id;
          if(this.clubInfo.ageDivision != null){
            this.UpdateCLub.ageDivision = this.clubInfo.ageDivision.id;
          }else{
            this.clubInfo.ageDivision = {id:""};
            this.UpdateCLub.ageDivision = "";
          }
          // this.UpdateCLub.type = resp.club_type;
          this.clubLogoImg =
            (resp.logo != null ?  process.env.VUE_APP_URL+resp.logo : "/dist/img/profile1.png") +
            "?v=" +
            moment().format("x");

          if(Object.prototype.hasOwnProperty.call(this.UpdateCLub, 'club_type') && resp.club_type != null)
            this.UpdateCLub.club_type = resp.club_type.split(",");

          if(Object.prototype.hasOwnProperty.call(this.UpdateCLub, 'club_disciplines') && Array.isArray(this.UpdateCLub.club_disciplines) && this.UpdateCLub.club_disciplines.length)
            this.UpdateCLub.club_disciplines = this.UpdateCLub.club_disciplines.map(item => item.id);

          if(Object.prototype.hasOwnProperty.call(this.UpdateCLub, 'retype_email'))
            this.UpdateCLub.retype_email = resp.email;

          this.UpdateCLub['primary_contact'] = resp['primary_contact'];
          if(this.UpdateCLub.primary_contact != undefined){
            this.UpdateCLub.primary_contact.state_code = this.UpdateCLub.primary_contact.state_code || this.UpdateCLub.state_code;
          }

          if(moment(new Date()).isAfter(moment(new Date(this.clubInfo.expires_at)))) {
            this.membershipExpired = true;
            this.insuranceUrl = "javascript:void(0);";
          } else {
            this.membershipExpired = false;
            this.insuranceUrl = "/club/purchase-insurance";
          }
          //alliance fast pitch profile handler
          if(!this.getSiteProps('general.member_profile_addition_info') && this.getSiteProps('general.member_profile_addition_info') != undefined){
            this.clubAdditionalInfoView = false;
            if(this.membership_id != 4){
              this.ageDivisionOPt = this.disciplines_list;
            }
          }
          if(this.subdomain == 'alliancefastpitch') {
            this.isNotalliancefastpitch =false;
            if(this.UpdateCLub.name == null){
              this.displayMissingMemFields = true;
              this.alertMessage = "Please fill out the Team Name #";
            }
          }

        })
        .catch((error) => {
          this.isProfileloading = false;
          console.log(error);
        });
    },
    handleSubmit(){
      this.displayAlert = false;
      axios
        .post(this.basePath + "api/club/info", this.UpdateCLub, {
          headers: this.clubHeaders,
        })
        .then(
          function(response) {
            this.alertMessage = this.prop_fields.club_info.tab_title +" updated successfully";
            this.displayAlert = true;
            var clubInfoData = JSON.parse(localStorage.getItem("team"));
            clubInfoData.name = response.data.data.club_name;
            localStorage.setItem("team", JSON.stringify(clubInfoData));
            this.updateLoggedUserInfo += 1;
            this.loadClubInfo();
          }.bind(this)
        )
        .catch(function(error) {
          console.log(error);
        });
    },
    updateClubInfo(bvModalEvt) {
      bvModalEvt.preventDefault()
      if(this.validate()){
          this.handleSubmit();
          // Hide the modal manually
          this.$nextTick(() => {
              this.$bvModal.hide('modal-center1')
          });
      }
    },
    ChangeClubMember(data) {
      data.contact_type = this.contactType;
      this.displayAlert = false;
      axios
        .post(this.basePath + "api/club/contacts", data, {
          headers: this.clubHeaders,
        })
        .then(
          function(response) {
            this.alertMessage = "Club member changed successfully";
            this.displayAlert = true;
            this.loadClubInfo();
          }.bind(this)
        )
        .catch(function(error) {
          console.log(error);
        });
    },
    resetAlert() {
      this.displayAlert = false;
    },
    setContactType(type) {
      this.contactType = type;
    },
    updateLogoInfo(e) {
      e.preventDefault();
      this.$set(this.clubHeaders, "content-type", "multipart/form-data");
      let formData = new FormData();
      formData.append("club_id", this.clubInfo.id);
      formData.append("image", this.logo);
      axios
        .post(this.basePath + "api/club/logo", formData, {
          headers: this.clubHeaders,
        })
        .then(
          function(response) {
            this.alertMessage = "Club logo changed successfully";
            this.displayAlert = true;
            var clubInfoData = JSON.parse(localStorage.getItem("team"));
            clubInfoData.logo = response.data.data.logo;
            localStorage.setItem("team", JSON.stringify(clubInfoData));
            this.updateLoggedUserInfo += 1;
            this.loadClubInfo();
          }.bind(this)
        )
        .catch(function(error) {
          console.log(error);
        });
      this.$nextTick(() => {
        this.$bvModal.hide("profile_pic_modal");
      });
      this.$set(this.clubHeaders, "content-type", "application/json");
    },
    resetModal() {
      this.uploadLogoFile = "";
      this.preview = "";
    },
    onImageChange(e) {
      let file = e.target.files[0];
      this.logo = file;
      let reader = new FileReader();
      reader.onloadend = (file) => {
        this.preview = reader.result;
      };
      reader.readAsDataURL(file);
    },
    getPreview() {
      let preview =
        this.preview.length > 200 ? this.preview : "/dist/img/profile1.png";
      return preview;
    },
    delTeamSites(id) {
      this.displayAlert = false;
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to delete the Club Ski-Site.",
          {
            title: "Please Confirm",
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (String(value) == "true") {
            axios
              .delete(this.basePath + "api/club/ski_site/" + id, {
                headers: this.clubHeaders,
              })
              .then(
                function() {
                  this.alertMessage = "Club Ski-Site deleted successfully";
                  this.displayAlert = true;
                  this.loadClubInfo();
                }.bind(this)
              )
              .catch(function(error) {
                console.log(error);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateSkiSites(evt) {
      evt.preventDefault();
      if (!this.skiSiteValidate()) {
        return;
      } else {
        axios
          .post(this.basePath + "api/club/ski_site", this.skiSites, {
            headers: this.clubHeaders,
          })
          .then(
            function(response) {
              this.alertMessage = "Club Ski-Site updated successfully";
              this.displayAlert = true;
              this.loadClubInfo();
            }.bind(this)
          )
          .catch(function(error) {
            console.log(error);
          });
        this.$nextTick(() => {
          this.$bvModal.hide("ski_sites_modal");
        });
      }
    },
    resetSkiModal() {
      this.skiSites.school = "";
      this.skiSites.address = "";
      this.skiSites.country = "";
      this.skiSites.state = "";
      this.skiSites.state_code = "";
      this.skiSites.city = "";
      this.skiSites.zip = "";
      this.skiSites.extId = "";
    },
    setVal(title, id = 0) {
      this.skiSiteAct = title;
      if (id !== 0) {
        axios
          .get(this.basePath + "api/club/ski_site/" + id, {
            headers: this.clubHeaders,
          })
          .then(
            function(response) {
              var resp = response.data.data;
              this.skiSites.school = resp.school;
              this.skiSites.address = resp.address;
              this.skiSites.country = resp.country;
              this.skiSites.state = resp.state;
              this.skiSites.state_code = resp.state_code;
              this.skiSites.city = resp.city;
              this.skiSites.zip = resp.zip;
              this.skiSites.is_primary = resp.is_primary;
              this.skiSites.extId = resp.id;
            }.bind(this)
          )
          .catch(function(error) {
            console.log(error);
          });
      } else {
        this.resetSkiModal();
      }
    },
    skiSiteValidate() {
      let error = "";
      this.skiSitesErrors.school =
        this.skiSites.school == "" ? "Enter valid ski-site name" : "";
      error += this.skiSites.school == "" ? "err" : "";
      // this.skiSitesErrors.country =
      //   this.skiSites.country == "" ? "Select valid country for Ski-Site" : "";
      // error += this.skiSites.country == "" ? "err" : "";
      // if (this.skiSites.country == "USA") {
         this.skiSitesErrors.state_code =
          this.skiSites.state_code == ""
            ? "Select valid state for Ski-Site"
            : "";
        error += this.skiSites.state_code == "" ? "err" : "";
      // } else {
      //   this.skiSitesErrors.state =
      //     this.skiSites.state == "" ? "Select valid state for Ski-Site" : "";
      //   error += this.skiSites.state == "" ? "err" : "";
      // }
      this.skiSitesErrors.city =
        this.skiSites.city == "" ? "Select valid state for Ski-Site" : "";
      error += this.skiSites.city == "" ? "err" : "";
      if(this.skiSites.zip == ""){
        this.skiSitesErrors.zip = "Zip code is required";
        error += "err";
      }
      else if(!this.skiSites.zip.match(/^\d{5}(-\d{4})?$/)){
        this.skiSitesErrors.zip = "Zip Code Should Be 5 Number.";
        error += "err";
      }
      else{
        this.skiSitesErrors.zip = "";
        error += "";
      }
      console.log(error);
      return error == "";
    },
    changeAutoRenewal(val) {
      console.log(val);

      if(val) {
        this.boxOne = ''


        this.$bvModal
          .msgBoxConfirm("Are you sure want to enable the Auto-Renewal?", {
             noCloseOnBackdrop: true,
           noCloseOnEsc: true,
            title: "Please Confirm",
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelVariant: "grey",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if(value){
              this.boxOne = value
              console.log( this.boxOne );
           if(this.boxOne == false){
               console.log( this.boxOne );
               this.$bvModal.hide('autoRenewalModal');
           }else if(this.boxOne == null){
                console.log( this.boxOne );
               this.$bvModal.hide('autoRenewalModal');
               }else{

             console.log( this.boxOne );
              this.$nextTick(() => {
               this.$bvModal.show('autoRenewalModal');
               });
           }
            } else {
              this.autoRenew = 0;
            }

          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$bvModal
            .msgBoxConfirm("Are you sure want to disable the Auto-Renewal?", {
              title: "Please Confirm",
              size: "md",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "YES",
              cancelTitle: "NO",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            })
            .then((value) => {
              if(value){
              this.autoRenewData.status = val;
              console.log( this.autoRenewData.status);
              axios
                  .post(
                      this.basePath + "api/club/auto-renew-status",
                      this.autoRenewData,
                      {headers: this.clubHeaders}
                  )
                  .then(
                      function (response) {
                        this.loadClubInfo();
                        this.alertMessage = "Auto renewal changed successfully";
                        this.displayAlert = true;
                      }.bind(this)
                  )
                  .catch(function (error) {
                    console.log(error);
                  });
              } else {
                this.autoRenew = 1;
              }
            })
            .catch((err) => {
              console.log(err);
            });
      }
    },

    updateCardAPI(){
      console.log(this.UpdCardInfo);
      if(this.clubInfo.registration.customerprofileid != null && this.clubInfo.registration.paymentprofileid != null){
          this.UpdCardInfo.auto_renew = 1;
          axios.post(this.basePath+"api/club/creditcard_info/"+this.clubInfo.registration.id, this.UpdCardInfo,{ headers:this.clubHeaders })
          .then(function (response) {
              if(response.data.status == "success"){
                this.loadClubInfo();
                this.alertMessage = "Auto renewal enabled successfully";
                this.displayAlert = true;
              } else {
                this.alertMessage = response.data.message;
                alert(this.alertMessage);
              }
              this.$bvModal.hide('autoRenewalModal');
              this.autoRenewProgress = false;
          }.bind(this))
          .catch(function (error) {
              console.log(error);
              this.autoRenewProgress = false;
          });
      }else{
          this.createCreditCardInfo();
      }
    },

    updateCreditCardInfo(){
        if(this.$refs.stripeForm != undefined){
            this.autoRenewProgress = true;

            if(this.stripeValidate == false){
                this.stripeError = "Please enter card details";
                this.autoRenewProgress = false;
                return;
            }

            this.$refs.stripeForm.submit();
        } else {
          if(!this.validateCard()){
            this.autoRenewProgress = true;
            this.updateCardAPI();
          }
        }
    },

    validateCard(){
        var error_flag = 0;
        this.cardError = {};
        if(this.UpdCardInfo.cardNumber == ""){
            this.cardError.cardNumber = "Card Number Required";
            error_flag++;
        }
        if(this.UpdCardInfo.expiration_year == ""){
            this.cardError.expiration_year = "Year Required";
            error_flag++;
        }
        if(this.UpdCardInfo.expiration_month == ""){
            this.cardError.expiration_month = "Month Required";
            error_flag++;
        }
        if(this.UpdCardInfo.cvv == ""){
            this.cardError.cvv = "CVV Required";
            error_flag++;
        }

        if(error_flag == 0){
            if(this.UpdCardInfo.expiration_year == new Date().getFullYear()){
                if(this.UpdCardInfo.expiration_month < moment(new Date()).format("MM")){
                    this.cardError.cardNumber = "Card is Expired";
                    error_flag++;
                }
            } else if(this.UpdCardInfo.expiration_year < new Date().getFullYear()) {
                this.cardError.cardNumber = "Card is Expired";
                error_flag++;
            }
        }

        return error_flag;
    },

    createCreditCardInfo(){
      console.log(this.UpdCardInfo);
      axios.post(this.basePath+"api/club/creditcard_info_create/"+this.clubInfo.registration.id, this.UpdCardInfo,{ headers:this.clubHeaders })
      .then(function (response) {
        if(response.data.status == "success"){
          this.loadClubInfo();
          this.alertMessage = "Auto renewal enabled successfully";
          this.displayAlert = true;
        } else {
          this.alertMessage = response.data.message;
          alert(this.alertMessage);
        }
          this.$bvModal.hide('autoRenewalModal');
          this.autoRenewProgress = false;
      }.bind(this))
      .catch(function (error) {
          console.log(error);
          this.autoRenewProgress = false;
      });
    },

    resetCardModal() {
      this.UpdCardInfo.stripeToken="";
      this.UpdCardInfo.cardNumber = "";
      this.UpdCardInfo.expiration_year = "";
      this.UpdCardInfo.expiration_month = "";
      this.UpdCardInfo.cvv = "";
      this.resetRenewalStatus();
    },
    resetRenewalStatus() {
      this.autoRenew = this.clubInfo.auto_renew;
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    triggerSelectDisciplinesAll(event){
          if(event.target.checked){
              var ids = Object.keys(this.clubDisciplinesList);
              if(ids.length){
                  ids.forEach(id => {
                      this.UpdateCLub.club_disciplines.push(id);
                  });
              }
          } else {
              this.UpdateCLub.club_disciplines = [];
          }
      },
      searchMember(){
        this.errors.club_admin_member_id = '';
        if(this.UpdateCLub.club_admin_member_id == '') {
          this.errors.club_admin_member_id = "Please enter the member_id";
          this.errorUpdateKey++;
        }
          this.clubAdminSearch.searched = false;
          this.clubAdminSearch.memberIDSearched = "";
          if(this.UpdateCLub.club_admin_member_id){
              axios.get(this.basePath + "api/member_lookup?member_id="+this.UpdateCLub.club_admin_member_id).then(res => {
                  if(res.data.data != undefined && res.data.data.length){
                      this.UpdateCLub.memId = this.UpdateCLub.club_admin_member_id;
                      this.errors.club_admin_name = "";
                      this.UpdateCLub.club_admin_name = res.data.data[0].personal_details.full_name
                  } else {
                      this.UpdateCLub.club_admin_name = "";
                  }
              }).catch(err => {
                  console.log(err);
                  this.clubAdminSearch.searched = true;
                  this.clubAdminSearch.memberIDSearched = this.UpdateCLub.club_admin_member_id;

              }).finally(() => {
                  this.clubAdminSearch.searched = true;
                  this.clubAdminSearch.memberIDSearched = this.UpdateCLub.club_admin_member_id;
              });
          }
      },
      validate() {
          this.errors = {}
          this.valid = false;
          var clubType = '', clubEmail = '', clubAddress = '', clubCity = '', clubStateCode = '', clubZip = '', clubAgeDivision = '';

          const validName = validateName(this.UpdateCLub.name);
          this.errors.name = validName.error;

        if(this.clubAdditionalInfoView) {
          clubType = validateSelect(this.UpdateCLub.type);
          this.errors.type = clubType.error;

          clubEmail = validateName(this.UpdateCLub.email);
          this.errors.email = clubEmail.error;

          clubAddress = validateName(this.UpdateCLub.address);
          this.errors.club_info_address = clubAddress.error;

          clubCity = validateName(this.UpdateCLub.city,true);
          this.errors.club_info_city = clubCity.error;

          clubStateCode = validateSelect(this.UpdateCLub.state_code);
          this.errors.club_info_state_code = clubStateCode.error;

          clubZip = validateZip(this.UpdateCLub.zip);
          this.errors.club_info_zip = clubZip.error;
        }else{
          clubAgeDivision = validateSelect(this.UpdateCLub.ageDivision.toString());
          this.errors.age_division = clubAgeDivision.error;
        }

        // if(this.prop_fields && this.prop_fields.club_info != undefined && this.prop_fields.club_info.clubname != undefined)
        //   validName.valid = true;
        // if(this.prop_fields && this.prop_fields.club_info != undefined && this.prop_fields.club_info.club_type != undefined)
        //   clubType.valid = true;
        // if(this.prop_fields && this.prop_fields.club_info != undefined && this.prop_fields.club_info.email != undefined)
        //   clubEmail.valid = true;

        if (validName.valid &&  clubType.valid && clubEmail.valid && clubAddress.valid && clubCity.valid && clubStateCode.valid && clubZip.valid ||
            (!this.clubAdditionalInfoView && clubAgeDivision.valid && validName.valid)) {
            this.valid = true;
        }
        if(this.clubAdditionalInfoView) {
          //Shooting
          var clubVisibleType = "";
          if (this.prop_fields.club_info != undefined
              && this.prop_fields.club_info.club_visible_type != undefined && this.prop_fields.club_info.club_visible_type.required != undefined
              && this.prop_fields.club_info.club_visible_type.required) {
            clubVisibleType = validateSelect(this.UpdateCLub.visibility_type);
            this.errors.club_visible_type = clubVisibleType.error;
          }
          var clubType2 = "";
          if (this.prop_fields.club_info != undefined
              && this.prop_fields.club_info.club_type != undefined && this.prop_fields.club_info.club_type.required != undefined
              && this.prop_fields.club_info.club_type.required) {
            clubType2 = validateSelect(this.UpdateCLub.club_type);
            if (Array.isArray(this.UpdateCLub.club_type)) {
              if (this.UpdateCLub.club_type.length == 0) {
                clubType2.valid = false;
                clubType2.error = "This field is required";
              } else {
                clubType2.valid = true;
                clubType2.error = "";
              }
            }
            this.errors.club_type = clubType2.error;
          }
          var clubdisciplines = "";
          if (this.prop_fields.club_info != undefined
              && this.prop_fields.club_info.club_discipilines != undefined && this.prop_fields.club_info.club_discipilines.required != undefined
              && this.prop_fields.club_info.club_discipilines.required) {
            clubdisciplines = validateCheck(this.UpdateCLub.club_disciplines);
            this.errors.club_discipilines = clubdisciplines.error;
          }
          var clubAdminID = "";
          if (this.prop_fields.club_info != undefined
              && this.prop_fields.club_info.club_admin_member_id != undefined && this.prop_fields.club_info.club_admin_member_id.required != undefined
              && this.prop_fields.club_info.club_admin_member_id.required) {
            clubAdminID = validateName(this.UpdateCLub.club_admin_member_id.toString());
            this.errors.club_admin_member_id = clubAdminID.error;
          }
          var clubAdminName = "";
          if (this.prop_fields.club_info != undefined
              && this.prop_fields.club_info.club_admin_name != undefined && this.prop_fields.club_info.club_admin_name.required != undefined
              && this.prop_fields.club_info.club_admin_name.required) {
            clubAdminName = validateName(this.UpdateCLub.club_admin_name);
            this.errors.club_admin_name = clubAdminName.error;
          }

          if (clubVisibleType != "" && clubType2 != "" && clubdisciplines != "" && clubAdminID != "" && clubAdminName != "") {
            if (clubVisibleType.valid && clubType2.valid && clubdisciplines.valid && clubAdminID.valid && clubAdminName.valid)
              this.valid = true;
            else
              this.valid = false;
          }
          //Shooting
        }
        return this.valid;
      },
      changeSameAddress(event){
        if(this.UpdateCLub.same_as_mailing_address){
            this.UpdateCLub.primary_contact.physical_address = this.UpdateCLub.primary_contact.address1;
            this.UpdateCLub.primary_contact.physical_city = this.UpdateCLub.primary_contact.city;
            this.UpdateCLub.primary_contact.physical_state_code = this.UpdateCLub.primary_contact.state_code;
            this.UpdateCLub.primary_contact.physical_zip = this.UpdateCLub.primary_contact.zip;
        }
      },
      validateTelinput(obj){
          this.validTelinput = obj.valid;
          console.log(obj);
          if(obj.valid){
              this.errors.mobilephone = '';
          }
      },
      validateContact(){
        this.errors = {};
        this.valid = false;
        var validAddress1 = "", validCity = "", validState = "", validCountry = "", validZip ="", validPhysicalZip = "";
        let validName = "";

        const validEmail = validateEmail(this.UpdateCLub.email,"E-Mail");
        this.errors.email = validEmail.error;

        const validRetypeEmail = validateEmail(this.UpdateCLub.retype_email, "Retype E-Mail");
        this.errors.retype_email = validRetypeEmail.error;
        if(this.clubAdditionalInfoView) {
          validAddress1 = validateAddressName(this.UpdateCLub.primary_contact.address1, "Club Address");
          this.errors.club_address = validAddress1.error;

          validCity = validateName(this.UpdateCLub.primary_contact.city, "City",true);
          this.errors.club_city = validCity.error;

          validState = validateSelect(this.UpdateCLub.primary_contact.state_code, "State");
          this.errors.club_state = validState.error;

          validZip = validateZip(this.UpdateCLub.primary_contact.zip, "Zip/Postal Code");
          this.errors.club_zip = validZip.error;

          validPhysicalZip = validateNonMandatoryZip(this.UpdateCLub.primary_contact.physical_zip, "Zip/Postal Code");
          this.errors.club_physical_zip = validPhysicalZip.error;

          validName = {valid:true};
        }else{
          console.log("data",this.UpdateCLub.address)
          validAddress1 = validateAddressName(this.UpdateCLub.address, "Team Address");
          this.errors.club_address = validAddress1.error;

          validCity = validateName(this.UpdateCLub.city, "City",true);
          this.errors.club_city = validCity.error;

          validState = validateSelect(this.UpdateCLub.state_code, "State");
          this.errors.club_state = validState.error;

          validCountry = validateSelect(this.UpdateCLub.country, "Country");
          this.errors.country = validCountry.error;

          validZip = validateZip(this.UpdateCLub.zip, "Zip/Postal Code");
          this.errors.club_zip = validZip.error;

          validName = validateName(this.UpdateCLub.name);

          const validPhone1 = validatePhone(this.UpdateCLub.phone, this.validTelinput);
          this.errors.homephone = validPhone1.error;
          const validPhone2 = validatePhone2(this.UpdateCLub.phone_2, this.validTelinput2);
          this.errors.mobilephone = validPhone2.error;
        }
        const validPhone1 = validatePhone(this.UpdateCLub.primary_contact.phone_1, this.validTelinput);
        this.errors.mobilephone = validPhone1.error;

        if(
            this.errors.email == null &&
            this.errors.retype_email == null &&
            this.errors.club_address == null &&
            this.errors.club_city == null &&
            this.errors.club_state == null &&
            this.errors.club_zip == null &&
            this.errors.mobilephone == null &&
            this.errors.homephone == null &&
            this.errors.club_physical_zip == null
        ){
            if(this.UpdateCLub.email != this.UpdateCLub.retype_email) {
                this.errors.retype_email = 'The email id does not match';
                return false;
            }
          if(!validName.valid){
            this.alertMessage = "Team Name is missing.<br> Please fill update.";
            this.displayAlert = true;
            this.setDangerBg();
            return false;
          }
          if(this.clubAdminSearch.searched == true && this.UpdateCLub.club_admin_name == '' || this.UpdateCLub.club_admin_member_id == ''){
              const clubAdminName = validateName(this.UpdateCLub.club_admin_name);
              this.errors.club_admin_name = clubAdminName.error;
              this.errors.club_admin_member_id = "Please enter the member_id";
              this.errorUpdateKey++;
              return false;
          }
            return true;
        }

        return false;
      },
      updateClubContact(bvModalEvt){
        bvModalEvt.preventDefault();
        this.isProfileloading = true;
        if(this.validateContact()){
          if(this.isNotalliancefastpitch) {
            this.UpdateCLub.address = this.UpdateCLub.primary_contact.address1;
            this.UpdateCLub.city = this.UpdateCLub.primary_contact.city;
            this.UpdateCLub.state_code = this.UpdateCLub.primary_contact.state_code;
            this.UpdateCLub.zip = this.UpdateCLub.primary_contact.zip;
            this.UpdateCLub.club_admin_member_id = this.UpdateCLub.memId;
          }
          this.handleSubmit();
          // Hide the modal manually
          this.$nextTick(() => {
              this.$bvModal.hide('edit-club-contact')
          });
        }
      },
      refreshCard(event){
        this.refreshProgress = true;
          let club = this.clubInfo.id;
          this.displayAlert = false;
          axios.post(this.basePath+"api/club/refresh-card", { club: club })
          .then(res => {
              this.alertMessage = "Card updated successfully";
              this.displayAlert = true;
              this.refreshProgress = false;
          })
          .catch(err => {
              console.log(err);
              this.refreshProgress = false;
          })
      },
      stripeTokenCreated(res){
          this.stripeError = "";
          this.stripeToken = res.id;
          this.UpdCardInfo.stripeToken = this.stripeToken;
          
          this.updateCardAPI();
      },
      captureStripeErrors(errors){
          this.stripeError = errors;
          this.autoRenewProgress = false;
      },
      stripeFormChanges(obj){
          this.stripeValidate = false;
          if(obj.complete){
              this.stripeError = "";
              this.stripeValidate = true;
          }
      },
    loadPropFields(){
      console.log("Data",this.subdomain);
      const retry = setInterval(()=>{
        if(this.subdomain == 'alliancefastpitch')
        {
          this.prop_fields = this.getSiteProps('memberRegistration.team.fields');
          if(this.prop_fields) clearTimeout(retry);
        }
        else{
          this.prop_fields = this.getSiteProps('memberRegistration.club.fields');
          if(this.prop_fields) clearTimeout(retry);
        }
      }, 100);


    },
    onTagState(valid, invalid, duplicate) {
      this.validTags = valid
      this.invalidTags = invalid
      this.duplicateTags = duplicate
    },
    emailValidator(email) {
      return email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/) && email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    },
    resetDisciplineData(){
    this.errorMessage="";
    },
    invitePlayer(bvModalEvt)
    {
      bvModalEvt.preventDefault();
      if (this.invite_players =="") {
        this.errorMessage = "Please add email address";
        return;
      }
      let invData={
        players:this.invite_players,
        team_id: this.clubInfo.id
      };
      axios.post(this.basePath + "api/club/invite_players", invData, {headers: this.clubHeaders})
      .then(function (response) {
        this.alertMessage = response.data.message;
        this.displayAlert = true;
        this.loadClubInfo();
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });

      this.$nextTick(() => {
        this.$bvModal.hide('invitePlayer-modal')
      });
    },
    delPlayers(id)
    {
      this.displayAlert = false;
      this.$bvModal.msgBoxConfirm("Are you sure to delete this ?" ,{
        title: 'Please Confirm',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        noCloseOnBackdrop: true,
          noCloseOnEsc: true,
        centered: true
      }).then(value => {

          console.log(value);
          if (String(value) == "true") {
            axios.delete(this.basePath + 'api/club/delete_players/' + id, {headers: this.clubHeaders})
            .then(function () {
              this.alertMessage = "Player deleted successfully";
              this.displayAlert = true;
              this.loadClubInfo();
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            });
          }
        })
          .catch(err => {
        console.log(err);
      });
        return false;        
    },
    fileChoosed(event){
          if(event.target.files.length) this.file = this.$refs.file.files[0];
          else this.file = null;
    },
    uploadCSV(event){
      
        let formData = new FormData();
        formData.append('file', this.file);
        formData.append('team_id', this.clubInfo.id);
        
        
        this.btnloading = true;
        axios.post(this.basePath+"api/club/import_players", formData, {
            headers: {
                "Authorization":"Bearer "+localStorage.getItem('clubToken'),
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
          this.btnloading = false;
          this.displayAlert = true;
          this.alertMessage= res.data.message;
          if(res.data.data.success > 0)
          { 
            this.loadClubInfo();
          }
        }).catch(err => {
           this.btnloading = false;
            console.log(err);
        })
    },
    getDiscipline(){
      axios.get(this.basePath+"api/getDisciplines")
          .then(response => {
            this.disciplines = response.data.data;
            let discipline_codes = Object.keys(this.disciplines);
            if(discipline_codes.length && this.disciplines_list.length == 0){
              discipline_codes.forEach(code => {
                this.disciplines_list.push({ id: code, text: this.disciplines[code] == "NA - N/A" ? "N/A" : this.disciplines[code] });
              });
            }
          })
          .catch(err => {
            // Manage the state of the application if the request
            // has failed
          })
    },
    validateTelinput2(obj){
      this.validTelinput2 = obj.valid;
      console.log(obj);
      if(obj.valid){
        this.errors.mobilephone = '';
      }
    },
    removeStateCode(){
      this.UpdateCLub.state = '';
      this.UpdateCLub.state_code = '';
    },
    setDangerBg(){
      setTimeout(() => {
        $('#alert_box').children('div').removeClass('alert-success');
        $('#alert_box').children('div').addClass('alert-danger');
      }, 100);
      return false;
    },
    removeDangerBg(){
      setTimeout(() => {
        $('#alert_box').children('div').removeClass('alert-danger');
        $('#alert_box').children('div').addClass('alert-success');
      }, 100);
    },
    loadTeamInsurances() {
      this.insuranceList = [];

      if (this.clubInfo?.insured_members?.length) {
        const currentYear = moment().year();

        this.insuranceList = this.clubInfo.insured_members
            .filter(member => {
              const endDateYear = moment(member.end_date).year();
              return currentYear <= endDateYear;
            });

        this.insuranceList = this.viewInsurancePrevious ? this.clubInfo?.insured_members : this.insuranceList;
        this.insuranceListKey++;
      }
    },
    loadClubSanctions() {
      this.sanctionList = [];
      if (this.clubInfo?.club_sanction_data?.length) {
        const currentYear = moment().year();

        this.sanctionList = this.clubInfo.club_sanction_data
            .filter(member => {
              const endDateYear = moment(member.end_date).year();
              return currentYear <= endDateYear;
            });
        this.sanctionList = this.viewSanctionPrevious ? this.clubInfo?.club_sanction_data : this.sanctionList;
        this.sanctionListKey++;
      }
    },
    changeInsuranceViewPrevious(event) {
      this.loadTeamInsurances();
    },
    changeSanctionViewPrevious(event) {
      this.loadClubSanctions();
    },
  },
  mounted() {
    this.sanctioningUrl = localStorage.getItem("ssoToken");
    this.loadClubInfo();
    this.getClubType();
    console.log("Data",this.subdomain);
    this.loadPropFields();
    this.getDiscipline();

    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
        if(modalId == "autoRenewalModal"){
            this.autoRenewalModalOpen = true;
        }
    })

    if(this.subdomain == "shooting"){
        this.getClubDisciplines();
    }
  },
  updated() {
    if(this.subdomain == 'alliancefastpitch'){
      if(this.prop_fields) this.prop_fields_contact = this.prop_fields.contact;
    }else{
      if(this.prop_fields) this.prop_fields_contact = this.prop_fields.club_contact;
    }
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from({ length: 8 }, (value, index) => year + index);
    },
  },
};
</script>

<style scoped>
.hovereffect {
  position: relative;
}
.avatar {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.overlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.hovereffect:hover .avatar {
  opacity: 0.3;
}

.hovereffect:hover .overlay {
  opacity: 1;
}

.text {
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  padding: 5px;
}
</style>
